<div class="d-block d-xxl-flex">
    <div style="width: 100%;min-width: 60%" class="px-3">

        <div class="row mt-4 mb-3 px-3 px-md-0">
            <div class="col-12 col-md-2 px-0 px-xs-2 d-flex flex-nowrap">
                <a href="javascript:void('');"
                   (click)="step !== 1 ? handlerBack(step - 1) : router.navigate(['/my-list'])">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                </a>
                <a href="javascript: $('#modalBackStep').modal('show'); void('');" class="ml-4"
                   *ngIf="(modelRequest.step > 1 && (modelRequest.status !== 'approve' && (user.profile === 'product_commercial' || user.profile === 'administrator'))) && modelRequest.status !== 'archived'">
                    <svg-icon class="svg size-md" src="assets/svg/repair.svg"></svg-icon>
                </a>
                <a href="javascript: $('#modalHistoryDetail').modal('show'); void('');" class="ml-4"
                   *ngIf="modelRequest.historyAudits?.length > 0 && (modelRequest.status !== 'approve')">
                    <svg-icon class="svg size-md" src="assets/svg/files.svg"></svg-icon>
                </a>
            </div>
            <div class="col-7 col-lg-8 text-center">
                <span class="header-title" *ngIf="!isEdit">{{ 'novocadastro.title' | translate }}</span>
                <span class="header-title" *ngIf="isEdit">{{ 'novocadastro.titleDetail' | translate }}</span>
            </div>
            <div class="col-3 col-lg-2 px-0 px-xs-2">
                <span class="header-date">{{ 'novocadastro.criado' | translate }}
                    <strong *ngIf="!isEdit">{{ createaAt | date: 'dd/MM/yyyy' }}</strong>
                    <strong *ngIf="isEdit">{{ modelRequest.createdAt | date: 'dd/MM/yyyy' }}</strong></span>
            </div>
        </div>

        <div class="py-3 bg-beige rounded pb-5 px-1 px-xs-4 pt-4">
            <div class="nav_step shadow mx-auto mb-4"
                 [ngClass]="{'d-none': !(modelRequest.status == 'open'|| modelRequest.status == 'archived' || isNullOrUndefined(modelRequest.status))}">
                <ul class="nav d-flex justify-content-between" id="myTab" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step1-tab" data-toggle="tab" href="#step1" role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step-legend">Comercial</span>
                            <span class="step">1</span>
                            <div class="position_item" *ngIf="!stepOneDisabled">
                                <span class="position_item-stage">{{ 'minhalista.input8.title' | translate }}</span>
                                <div class="question"><span
                                        class="position_item-name">{{ 'novocadastro.etapa1.title' | translate }}</span>
                                </div>
                            </div>

                            <div class="position_item"
                                 *ngIf="stepOneDisabled && !this.isNullOrUndefined(handlerDateTitle(1))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}</span> <br>
                                    <div class="font-weight-600 text-truncate">{{ handlerNameTitle(1) }}</div>
                                    Comercial
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(1) }}</span>
                                    <!--<br>
                                    {{'novocadastro.etapa1.title' | translate}} -->
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tab" data-toggle="tab" href="#step2" role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step-legend">AR Local</span>
                            <span class="step">2</span>
                            <div class="position_item" *ngIf="!stepTwoDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR Local</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepTwoDisabled && !this.isNullOrUndefined(handlerDateTitle(2))">
                                <span class="position_item-name large">
                                    <span class="font-weight-normal">{{ handlerDateTitle(2) | date: 'dd/MM/yyyy' }}</span> <br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(2) }}</span> <br>
                                    AR Local
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(2) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(2) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step3-tab" data-toggle="tab" href="#step3" role="tab"
                           aria-controls="step3" aria-selected="false">
                            <span class="step-legend">
                                <img class="step-legend-brand"
                                     src="assets/images/country/{{modelRequest.area_400 === 'PMOINT' ? modelRequest.countryManufacturingPmoInt + '.svg' : 'brazil.svg'}}"
                                     alt=""/>
                                Artes
                            </span>
                            <span class="step">3</span>
                            <div class="position_item" *ngIf="!stepThreeDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Artes</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepThreeDisabled && !this.isNullOrUndefined(handlerDateTitle(3))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{ handlerDateTitle(3) | date: 'dd/MM/yyyy' }}</span><br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(3) }}</span> <br>
                                    Artes
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(3)| date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(3) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step4-tab" data-toggle="tab" href="#step4" role="tab"
                           aria-controls="step4" aria-selected="false">
                            <span class="step-legend">
                                <!--<img class="step-legend-brand" src="assets/images/country/brazil.svg" alt="" />-->
                                GAMAL (NCM)
                            </span>
                            <span class="step">4</span>
                            <div class="position_item text-right" *ngIf="!stepFourDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">GAMAL (NCM)</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFourDisabled && !this.isNullOrUndefined(handlerDateTitle(4))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{ handlerDateTitle(4) | date: 'dd/MM/yyyy' }}</span><br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(4) }}</span> <br>
                                    GAMAL (NCM)
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    <span class="d-none"
                                          *ngIf="isNullOrUndefined(handlerDateTitle(4))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(4))">
                                        {{ handlerDateTitle(4) | date: 'dd/MM/yyyy' }}
                                        <span class="d-block font-weight-600"> {{ handlerNameTitle(4) }}</span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step5-tab" data-toggle="tab" href="#step5" role="tab"
                           aria-controls="step5" aria-selected="false">
                            <span class="step-legend">
                                <!--<img class="step-legend-brand" src="assets/images/country/brazil.svg" alt="" />-->
                                {{ 'planejamentoTributario' | translate }}
                            </span>
                            <span class="step">5</span>
                            <div class="position_item text-right" *ngIf="!stepFourDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">{{ 'planejamentoTributario' | translate }}</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFourDisabled && !this.isNullOrUndefined(handlerDateTitle(4))">
                                <span class="position_item-name large">
                                    <span class="font-weight-normal">{{ handlerDateTitle(4) | date: 'dd/MM/yyyy' }}</span><br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(4) }}</span> <br>
                                    {{ 'planejamentoTributario' | translate }}
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    <span class="d-none"
                                          *ngIf="isNullOrUndefined(handlerDateTitle(4))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(4))">
                                        {{ handlerDateTitle(5) | date: 'dd/MM/yyyy' }}
                                        <span class="d-block font-weight-600"> {{ handlerNameTitle(4) }}</span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </li>

                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step6-tab" data-toggle="tab" href="#step6" role="tab"
                           aria-controls="step6" aria-selected="false">
                            <span class="step-legend">
                                GLP
                            </span>
                            <span class="step">6</span>
                            <div class="position_item text-right" *ngIf="!stepFiveDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">GLP</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFiveDisabled && !this.isNullOrUndefined(handlerDateTitle(4))">
                                <span class="position_item-name large">
                                    <span class="font-weight-normal">{{ handlerDateTitle(4) | date: 'dd/MM/yyyy' }}</span><br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(4) }}</span> <br>
                                    GLP
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    <span class="d-none"
                                          *ngIf="isNullOrUndefined(handlerDateTitle(4))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(4))">
                                        {{ handlerDateTitle(5) | date: 'dd/MM/yyyy' }}
                                        <span class="d-block font-weight-600"> {{ handlerNameTitle(4) }}</span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </li>

                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step7-tab" data-toggle="tab" href="#step7" role="tab"
                           aria-controls="step7" aria-selected="false">
                            <span class="step-legend">
                                <img class="step-legend-brand"
                                     src="assets/images/country/{{modelRequest.area_400 === 'PMOINT' ? modelRequest.countryManufacturingPmoInt + '.svg' : 'brazil.svg'}}"
                                     alt=""/>
                                GQ DOC
                            </span>
                            <span class="step">7</span>
                            <div class="position_item text-right" *ngIf="!stepSixDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Cadastro</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepSixDisabled && !this.isNullOrUndefined(handlerDateTitle(5))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{ handlerDateTitle(6) | date: 'dd/MM/yyyy' }}</span> <br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(6) }}</span> <br>
                                    Cadastro
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(5) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(6) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>


            <div class="nav_step shadow mx-auto mb-5"
                 [ngClass]="{'d-none': !(!isNullOrUndefined(modelRequest.status) && (modelRequest.status !== 'open' && modelRequest.status !== 'archived'))}">
                <ul class="nav d-flex justify-content-between" id="myTabF" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step1-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 1, 'finish' : modelRequest.step >= 1}"
                           href="#step1" role="tab" aria-controls="step1" aria-selected="true">
                            <span class="step-legend">Comercial</span>
                            <span class="step">1</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    {{ 'novocadastro.etapa1.title' | translate }} <br>
                                    <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}
                                        <br>
                                        {{ handlerNameTitle(1) }}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(1) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 2, 'finish' : modelRequest.step >= 2}"
                           href="#step2" role="tab" aria-controls="step2" aria-selected="false">
                            <span class="step-legend">AR Local</span>
                            <span class="step">2</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    AR Local <br>
                                    <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(2) | date: 'dd/MM/yyyy' }}
                                        <br>
                                        {{ handlerNameTitle(2) }}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(2) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(2) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step3-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 3, 'finish' : modelRequest.step >= 3}"
                           href="#step3" role="tab" aria-controls="step3" aria-selected="false">
                            <span class="step">3</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    Artes <br>
                                    <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(3) | date: 'dd/MM/yyyy' }}
                                        <br>
                                        {{ handlerNameTitle(3) }}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(3) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(3) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step4-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 4, 'finish' : modelRequest.step >= 4}"
                           href="#step4" role="tab" aria-controls="step4" aria-selected="false">
                            <span class="step">4</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    GAMAL (NCM) <br>
                                    <span *ngIf="isNullOrUndefined(handlerDateTitle(4))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(4))">
                                        <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(4) | date: 'dd/MM/yyyy' }}
                                            <br>
                                            {{ handlerNameTitle(4) }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(4) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(4) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step5-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 5, 'finish' : modelRequest.step >= 5}"
                           href="#step5" role="tab" aria-controls="step5" aria-selected="false">
                            <span class="step">5</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    {{ 'planejamentoTributario' | translate }} <br>
                                    <span *ngIf="isNullOrUndefined(handlerDateTitle(5))">Não aplicável</span>
                                    <span *ngIf="!isNullOrUndefined(handlerDateTitle(5))">
                                        <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(5) | date: 'dd/MM/yyyy' }}
                                            <br>
                                            {{ handlerNameTitle(5) }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(5) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(4) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step6-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 6, 'finish' : modelRequest.step >= 6}"
                           href="#step6" role="tab" aria-controls="step6" aria-selected="false">
                            <span class="step">6</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    Cadastro <br>
                                    <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(6) | date: 'dd/MM/yyyy' }}
                                        <br>
                                        {{ handlerNameTitle(6) }}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(6) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(6) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="tab-content" id="myTabContent">

            <!--request-one-->
            <div class="tab-pane fade show active" id="step1" role="tabpanel" aria-labelledby="step1-tab">
                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-5">
                        <div class="col-12">
                            <p class="font-weight-bold primary">{{'step1.ola' | translate}} :)</p>
                            <p>{{'step1.p1' | translate}}
                            </p>
                            <br>
                            <p>{{'step1.p2' | translate}}</p>
                            <p>{{'ifyouhavedoubts'|translate}}</p>
                            <!--<div class="input-material">
                                <input class="form-control" id="name" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.name" [disabled]="stepOneDisabled"/>
                                <label for="name"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'general.nomeprojeto' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>-->


                            <!--<div class="input-material">
                                <input class="form-control" id="code" maxlength="200" type="text" required
                                       appOnlyNumber
                                       data-toggle="tooltip"
                                       data-placement="top" title="{{'codigomodeloquetenha' | translate}}"
                                       [(ngModel)]="modelRequest.code" [disabled]="stepOneDisabled"/>
                                <label for="code"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'general.codigomodelo' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop2.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop2.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>-->
                            <div class="input-material">
                                <select id="reasonRequest" class="form-control" name="type"
                                        data-toggle="tooltip"
                                        data-placement="top" title="{{'lancamentodenovoproduto' | translate}}"
                                        [(ngModel)]="modelRequest.reasonRequest" [disabled]="stepOneDisabled">
                                    <option [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option [ngValue]="0">{{'general.launch' | translate}}</option>
                                    <option [ngValue]="1">{{'general.newSendBulk' | translate}}</option>
                                    <!--<option *ngFor="let c of listChannel" [ngValue]="c.name">{{c.description}}</option>-->
                                </select>
                                <label [ngClass]="{'disabled' : stepOneDisabled}">{{'general.reasonRequest' | translate}}
                                    *</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step1.pop3.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step1.pop3.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>


                            <div class="input-material question" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'paralancamentodesubsidiaria'|translate}}">
                                <select id="channel" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.channelDistribution" [disabled]="stepOneDisabled">
                                    <option disabled selected
                                            [ngValue]="null">{{ 'general.selecione' | translate }}
                                    </option>
                                    <option *ngFor="let c of listChannel" [ngValue]="c.name">{{ c.name }}</option>
                                </select>
                                <label for="channel" [ngClass]="{'disabled' : stepOneDisabled}"
                                       class="mb-0 mr-3">{{ 'novocadastro.etapa2.input2' | translate }}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop2.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop2.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>

                            <div class="input-material question" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'informarmesmaunidadedecodigosap'|translate}}">
                                <select id="unity" class="form-control" name="unity"
                                        [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepOneDisabled">
                                    <option disabled selected
                                            [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listMeasure" [ngValue]="c.name">{{c.description}}</option>
                                </select>
                                <label for="unity" [ngClass]="{'disabled' : stepOneDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop3.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop3.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="w-100">
                                <div class="text-left mb-2">
                                    <p class="font-weight-bold"><span
                                            class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span></p>
                                    <p>{{'step2.texto1' | translate}}</p>
                                </div>
                                <input class="form-control" id="descriptionIptStep3" [(ngModel)]="modelRequest.description"
                                       [readOnly]="false" [disabled]="stepOneDisabled" type="text" maxlength="33" required/>

                                <div class="w-100 d-block mb-4 mt-4"
                                     *ngIf="!stepOneDisabled">
                                    <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                       class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 mt-3" *ngIf="(stepOneDisabled && modelRequest.area_400 !== 'PMOINT') || (!stepOneDisabled && country === 'colombia')">

                            <div class="bg-beige rounded p-3">
                                <h6 class="text-primary">{{'general.labelCountry3' | translate}}</h6>
                                <p class="mb-2"
                                   *ngFor="let x of modelRequestResult.countryNotifications">{{getCountryNameByKey(x)}}</p>
                            </div>
                        </div>
                        <div class="col-12 mt-3" *ngIf="!stepOneDisabled && modelRequest.area_400 !== 'PMOINT'">
                            <div class="w-100">
                                <div class="bg-beige rounded p-3">
                                    <ng-container *ngIf="country == 'uruguai'">
                                        <h6 class="text-primary">{{'general.labelCountry1' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="uruguai"
                                                           (click)="handlerSelectedCountry('uruguai')"
                                                           [checked]="handlerCheckCountry('uruguai')"
                                                           [disabled]="handlerDisabledCountry('uruguai')">
                                                    <label class="custom-control-label"
                                                           for="uruguai">{{'country.uruguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="bolivia"
                                                           (click)="handlerSelectedCountry('bolivia')"
                                                           [checked]="handlerCheckCountry('bolivia')"
                                                           [disabled]="handlerDisabledCountry('bolivia')">
                                                    <label class="custom-control-label"
                                                           for="bolivia">{{'country.bolivia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="paraguai"
                                                           (click)="handlerSelectedCountry('paraguai')"
                                                           [checked]="handlerCheckCountry('paraguai')"
                                                           [disabled]="handlerDisabledCountry('paraguai')">
                                                    <label class="custom-control-label"
                                                           for="paraguai">{{'country.paraguai' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="handlerCheckCountryVisible()">
                                        <h6 class="text-primary">{{'general.labelCountry1' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="argentina1"
                                                           (click)="handlerSelectedCountry('argentina')"
                                                           [checked]="handlerCheckCountry('argentina')"
                                                           [disabled]="handlerDisabledCountry('argentina')">
                                                    <label class="custom-control-label"
                                                           for="argentina1">{{'country.argentina' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="bolivia1"
                                                           (click)="handlerSelectedCountry('bolivia')"
                                                           [checked]="handlerCheckCountry('bolivia')"
                                                           [disabled]="handlerDisabledCountry('bolivia')">
                                                    <label class="custom-control-label"
                                                           for="bolivia1">{{'country.bolivia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="brazil1"
                                                           (click)="handlerSelectedCountry('brazil')"
                                                           [checked]="handlerCheckCountry('brazil')"
                                                           [disabled]="handlerDisabledCountry('brazil')">
                                                    <label class="custom-control-label"
                                                           for="brazil1">{{'country.brazil' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="chile1"
                                                           (click)="handlerSelectedCountry('chile')"
                                                           [checked]="handlerCheckCountry('chile')"
                                                           [disabled]="handlerDisabledCountry('chile')">
                                                    <label class="custom-control-label"
                                                           for="chile1">{{'country.chile' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="colombia1"
                                                           (click)="handlerSelectedCountry('colombia')"
                                                           [checked]="handlerCheckCountry('colombia')"
                                                           [disabled]="handlerDisabledCountry('colombia')">
                                                    <label class="custom-control-label"
                                                           for="colombia1">{{'country.colombia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="equador1"
                                                           (click)="handlerSelectedCountry('equador')"
                                                           [checked]="handlerCheckCountry('equador')"
                                                           [disabled]="handlerDisabledCountry('equador')">
                                                    <label class="custom-control-label"
                                                           for="equador1">{{'country.equador' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="mexico1"
                                                           (click)="handlerSelectedCountry('mexico')"
                                                           [checked]="handlerCheckCountry('mexico')"
                                                           [disabled]="handlerDisabledCountry('mexico')">
                                                    <label class="custom-control-label"
                                                           for="mexico1">{{'country.mexico' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="peru1"
                                                           (click)="handlerSelectedCountry('peru')"
                                                           [checked]="handlerCheckCountry('peru')"
                                                           [disabled]="handlerDisabledCountry('peru')">
                                                    <label class="custom-control-label"
                                                           for="peru1">{{'country.peru' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="paraguai1"
                                                           (click)="handlerSelectedCountry('paraguai')"
                                                           [checked]="handlerCheckCountry('paraguai')"
                                                           [disabled]="handlerDisabledCountry('paraguai')">
                                                    <label class="custom-control-label"
                                                           for="paraguai1">{{'country.paraguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="uruguai1"
                                                           (click)="handlerSelectedCountry('uruguai')"
                                                           [checked]="handlerCheckCountry('uruguai')"
                                                           [disabled]="handlerDisabledCountry('uruguai')">
                                                    <label class="custom-control-label"
                                                           for="uruguai1">{{'country.uruguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="venezuela"
                                                           (click)="handlerSelectedCountry('venezuela')"
                                                           [checked]="handlerCheckCountry('venezuela')"
                                                           [disabled]="handlerDisabledCountry('venezuela')">
                                                    <label class="custom-control-label"
                                                           for="venezuela">{{'country.venezuela' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="mocambique"
                                                           (click)="handlerSelectedCountry('mocambique')"
                                                           [checked]="handlerCheckCountry('mocambique')"
                                                           [disabled]="handlerDisabledCountry('mocambique')">
                                                    <label class="custom-control-label"
                                                           for="mocambique">{{'country.mocambique' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="angola"
                                                           (click)="handlerSelectedCountry('angola')"
                                                           [checked]="handlerCheckCountry('angola')"
                                                           [disabled]="handlerDisabledCountry('angola')">
                                                    <label class="custom-control-label"
                                                           for="angola">{{'country.angola' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="handlerCheckCountryVisible('ceam')">
                                        <h6 class="text-primary">{{'general.labelCountry2' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="belize2"
                                                           (click)="handlerSelectedCountry('belize', 'ceam')"
                                                           [checked]="handlerCheckCountry('belize', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('belize', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="belize2">{{'country.belize' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="costa-rica2"
                                                           (click)="handlerSelectedCountry('costa-rica', 'ceam')"
                                                           [checked]="handlerCheckCountry('costa-rica', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('costa-rica', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="costa-rica2">{{'country.costa-rica' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="guatemala2"
                                                           (click)="handlerSelectedCountry('guatemala', 'ceam')"
                                                           [checked]="handlerCheckCountry('guatemala', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('guatemala', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="guatemala2">{{'country.guatemala' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="honduras2"
                                                           (click)="handlerSelectedCountry('honduras', 'ceam')"
                                                           [checked]="handlerCheckCountry('honduras', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('honduras', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="honduras2">{{'country.honduras' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="nicaragua2"
                                                           (click)="handlerSelectedCountry('nicaragua', 'ceam')"
                                                           [checked]="handlerCheckCountry('nicaragua', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('nicaragua', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="nicaragua2">{{'country.nicaragua' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="panama2"
                                                           (click)="handlerSelectedCountry('panama', 'ceam')"
                                                           [checked]="handlerCheckCountry('panama', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('panama', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="panama2">{{'country.panama' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="republica-dominicana2"
                                                           (click)="handlerSelectedCountry('republica-dominicana', 'ceam')"
                                                           [checked]="handlerCheckCountry('republica-dominicana', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('republica-dominicana', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="republica-dominicana2">{{'country.republica-dominicana' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="el-salvador"
                                                           (click)="handlerSelectedCountry('el-salvador', 'ceam')"
                                                           [checked]="handlerCheckCountry('el-salvador', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('el-salvador', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="el-salvador">{{'country.el-salvador' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div>
                                <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                   [attr.data-content]="getTranslateInstantKey('wheninformingcountry')">
                                    <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!--request-two-->
            <div class="tab-pane fade" id="step2" role="tabpanel" aria-labelledby="step2-tab">

                <div class="mx-auto p-4 tab-pane-content large">
                    <div class="row mt-5">

                        <div class="col-12 col-md-6 pr-md-5">

                            <div class="input-material">
                                <input class="form-control" id="recordHolder2" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.recordHolder" [disabled]="stepTwoDisabled"/>
                                <label for="recordHolder2"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'novocadastro.etapa4.secao2.card3.detentor' | translate }}
                                    *</label>
                                <div>

                                </div>
                            </div>

                            <div class="input-material">
                                <select id="dispatchedBy" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.dispatchedBy" [disabled]="stepTwoDisabled">
                                    <option disabled selected [ngValue]="null">{{ 'general.selecione' | translate }}
                                    </option>
                                    <option *ngFor="let c of listDispatched" [ngValue]="c.name">{{ c.name }}</option>
                                </select>
                                <label for="dispatchedBy"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'expedidoPor' | translate }}
                                    *</label>
                                <div></div>
                            </div>

                            <div class="input-material question">
                                <input class="form-control" id="registerMinistry" maxlength="200" type="text"
                                       [disabled]="stepTwoDisabled"
                                       [maxLength]="20"
                                       [(ngModel)]="modelRequest.ministryRecord" required/>
                                <label for="registerMinistry" class="col-10 p-0 text-truncate"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'novocadastro.etapa4.secao2.card3.registro' | translate }}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block" data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop6.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop6.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>

                            <div class="input-material" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'indiquedatadefechamento'|translate}}">
                                <input class="form-control js-daterangepicker" id="validity" type="text" maxlength="10"
                                       mask="00/00/0000"
                                       [disabled]="stepTwoDisabled" required/>

                                <label for="validity" class="text-truncate" style="max-width: 75%"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'novocadastro.etapa4.secao2.card3.validade' | translate }}</label>
                                <div>
                                    <a href="javascript:void('')" data-toggle="popover" class="question_beside d-block"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop7.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop7.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="Manufacturer1" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.manufacturer" [disabled]="stepTwoDisabled"
                                       required/>
                                <label for="Manufacturer1" class="col-10 p-0 text-truncate"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa2.input7' | translate}}</label>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple1" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple1" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple1"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    1</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple2" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple2" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple2"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    2</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple3" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple3" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple3"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    3</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple4" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple4" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple4"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    4</label>
                                <div></div>
                            </div>

                            <div class="d-flex justify-content-between gap-20">
                                <div class="input-material">
                                    <input class="form-control" id="dcb1"  type="text" required
                                           [(ngModel)]="modelRequest.dcb1" [disabled]="stepTwoDisabled"/>
                                    <label for="dcb1"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">DCB 1</label>
                                    <div></div>
                                </div>
                                <div class="input-material">
                                    <input class="form-control" id="dcb2"  type="text" required
                                           [(ngModel)]="modelRequest.dcb2" [disabled]="stepTwoDisabled"/>
                                    <label for="dcb2"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">DCB 2</label>
                                    <div></div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between gap-20">
                                <div class="input-material">
                                    <input class="form-control" id="dcb3"  type="text" required
                                           [(ngModel)]="modelRequest.dcb3" [disabled]="stepTwoDisabled"/>
                                    <label for="dcb3"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">DCB 3</label>
                                    <div></div>
                                </div>
                                <div class="input-material">
                                    <input class="form-control" id="dcb4"  type="text" required
                                           [(ngModel)]="modelRequest.dcb4" [disabled]="stepTwoDisabled"/>
                                    <label for="dcb4"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">DCB 4</label>
                                    <div></div>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 col-md-6 pl-md-5">

                            <div class="input-material">
                                <input class="form-control" id="anvisaClassification" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.anvisaClassification" [disabled]="stepTwoDisabled"/>
                                <label for="anvisaClassification"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'anvisaClassificacao' | translate }}
                                </label>
                                <div></div>
                            </div>

                            <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-20">
                                <div class="input-material w-100">
                                    <input class="form-control" id="productPresentation" maxlength="200" type="text" required
                                           [(ngModel)]="modelRequest.productPresentation" [disabled]="stepTwoDisabled" />
                                    <label for="productPresentation"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{'concentracaoFormaQuantidade' | translate}}
                                        *</label>
                                    <div>
                                        <a href="javascript:void('')" class="d-block question_beside_right" data-toggle="popover"
                                           [attr.title]="'Ex.: 40MG+20MG COMP REV x 15'"
                                           [attr.data-content]="'Apresentação do produto Concentração x forma farmacêutica x quantidade'">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>

                                <div class="input-material w-100">
                                    <input class="form-control" id="therapyClass"  type="text"
                                           [(ngModel)]="modelRequest.therapyClass" [disabled]="stepTwoDisabled" required />
                                    <label for="therapyClass"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'codigoClasseTerapeutica' | translate }}
                                        *</label>
                                    <div></div>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="applicationType" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.applicationType" [disabled]="stepTwoDisabled"/>
                                <label for="applicationType"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'formaAplicacao' | translate }}
                                </label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="referenceMed" maxlength="30" type="text" required
                                       [(ngModel)]="modelRequest.referenceMed" [disabled]="stepTwoDisabled"/>
                                <label for="referenceMed"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'medicamentoReferencia' | translate }}
                                </label>
                                <div></div>
                            </div>

                            <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-20">
                                <div class="input-material w-100">
                                    <input class="form-control" id="doseUnit" maxlength="200" type="text" required
                                           [(ngModel)]="modelRequest.doseUnit" [disabled]="stepTwoDisabled" />
                                    <label for="doseUnit"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{'unidadeDose' | translate}}
                                    </label>
                                    <div></div>
                                </div>

                                <div class="input-material w-100">
                                    <input class="form-control" id="pharmForm" type="text" required
                                           [(ngModel)]="modelRequest.pharmForm" [disabled]="stepTwoDisabled" />
                                    <label for="pharmForm"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'formaFarmaceutica' | translate }}
                                    </label>
                                    <div></div>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="productStripe" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.productStripe" [disabled]="stepTwoDisabled" />
                                <label for="productStripe"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'tarjaProduto' | translate}}
                                    *</label>
                                <div></div>
                            </div>

                            <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-20">
                                <div class="input-material w-100">
                                    <input class="form-control" id="storageCondition" maxlength="200" type="text"
                                           [(ngModel)]="modelRequest.storageCondition" [disabled]="stepTwoDisabled" required />
                                    <label for="storageCondition"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{'condicaoArmazenagem' | translate}}
                                        *</label>
                                    <div></div>
                                </div>

                                <div class="input-material w-100">
                                    <input class="form-control" id="expirationDate" maxlength="4" type="number" inputmode="numeric"
                                           [(ngModel)]="modelRequest.expirationDate" [disabled]="stepTwoDisabled"  required />
                                    <label for="expirationDate"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{'prazoValidadeMeses' | translate}}
                                        *</label>
                                    <div></div>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="originalProductPresentation" maxlength="200" type="text"
                                       [(ngModel)]="modelRequest.originalProductPresentation" [disabled]="stepTwoDisabled" required />
                                <label for="originalProductPresentation"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'apresentacaoProdutoOriginal' | translate}}
                                </label>
                                <div></div>
                            </div>

                            <div class="input-material question">
                                <select id="medicalPrescription" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.medicalPrescription"
                                        [disabled]="stepTwoDisabled">
                                    <option value="n-a" selected>N/A</option>
                                    <option value="sim">Sim</option>
                                    <option value="nao">Não</option>
                                </select>
                                <label for="medicalPrescription" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'vendaPrescricaoMedica' | translate}}</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="sectorOfActivity" maxlength="200" type="text"
                                       [(ngModel)]="modelRequest.sectorOfActivity" [disabled]="stepTwoDisabled" required />
                                <label for="sectorOfActivity"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'setorAtividade' | translate}}
                                </label>
                                <div></div>
                            </div>

                            <div class="input-material question">
                                <select id="productType" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.productType"
                                        [disabled]="stepTwoDisabled">
                                    <option value="n-a" selected>N/A</option>
                                    <option value="vermelha">Vermelha</option>
                                    <option value="preta">Preta</option>
                                </select>
                                <label for="productType" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'tipoProduto' | translate}}</label>
                                <div></div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <!--request-three-->
            <div class="tab-pane fade" id="step3" role="tabpanel" aria-labelledby="step3-tab">
                <!--<div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material question">

                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'escrevaseelancamentoou'|translate}}" class="form-control" id="request3" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.motive"
                                       required [disabled]="stepThreeDisabled"/>
                                <label for="request"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input1' | translate}}</label>

                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>

                            <div class="input-material question col-lg-12 mr-3">
                                &lt;!&ndash;<input class="form-control js-daterangepicker" id="validity1" type="text" maxlength="10"
                                       [disabled]="stepThreeDisabled" required/>
                                <label for="validity1"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa4.secao2.card3.validade' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop7.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>&ndash;&gt;

                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'informarmesesdevidautil'|translate}}" type="number" class="form-control text-primary" min="0" id="dueDateR"
                                       [(ngModel)]="modelRequest.dueDateRevised" [disabled]="stepThreeDisabled"
                                       oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                       onchange="if (this.value > 60) this.value = 60"
                                       aria-describedby="basic-addon2" maxlength="2">
                                <label for="dueDateR"
                                       class="text-truncate" style="max-width: 75%"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa3.inputDeadline' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       title="{{'novocadastro.etapa3.inputDeadline' | translate}}"
                                       [attr.data-content]="getTranslateInstantKey('step2.p1')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'portaria344'|translate}}" class="input-material disabled question mt-5" *ngIf="!stepThreeDisabled">
                                <select class="form-control" [(ngModel)]="modelDescriptionPortaria"
                                        (change)="setModelPortariaDescription()" [disabled]="stepThreeDisabled"
                                        required>
                                    <option value="">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listPort" [ngValue]="c.name">{{c.description}}</option>
                                </select>
                                <label>Portaria 344/98</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step3.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step3.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>


                            <div class="modal-body modal-body&#45;&#45;small pt-0">
                                <div class="row flex-column">
                                    <div class="text-center mx-auto font-weight-bold text-primary mt-0 mb-5 size-lg">
                                        Verificação
                                    </div>
                                    <div class="bg-beige rounded pt-3 pb-1 px-10">
                                        <div class="bg-beige rounded pt-3 pb-1 px-4">
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch100" [(ngModel)]="isChecked1">
                                                    <label class="custom-control-label" for="customSwitch100">
                                                        {{'popovers.step3.check1' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch101" [(ngModel)]="isChecked2">
                                                    <label class="custom-control-label" for="customSwitch101">
                                                        {{'popovers.step3.check2' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch102" [(ngModel)]="isChecked3">
                                                    <label class="custom-control-label" for="customSwitch102">
                                                        {{'popovers.step3.check3' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="text-left mb-2">
                                <p class="font-weight-bold"><span
                                        class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span></p>
                                <p>{{'step2.texto1' | translate}}</p>
                            </div>
                            <input class="form-control" id="descriptionIptStep3" [(ngModel)]="modelRequest.description"
                                   [readOnly]="false" type="text" maxlength="33" required/>

                            <div class="w-100 d-block mb-4 mt-4"
                                 *ngIf="!stepThreeDisabled">
                                <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                   class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                            </div>
                        </div>

                    </div>
                    <div class="d-none">
                        <div class="row px-0 px-md-5">
                            <div class="col-md-6">

                                <div class="input-material question">
                                    <select id="channel3" class="form-control" name="type"
                                            [(ngModel)]="modelRequest.channelDistribution"
                                            [disabled]="stepThreeDisabled">
                                        <option disabled selected value="undefined">Selecione</option>
                                        <option *ngFor="let c of listChannel"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label for="channel" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input2' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor,  elegir el canal donde será distribuido el producto"
                                           data-content="Generalmente, para operaciones internacionales, el canal correcto es subsidiarias">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>

                                </div>
                                <div class="input-material question">
                                    <select id="unity3" class="form-control" name="unity"
                                            [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepThreeDisabled">
                                        <option disabled selected
                                                value="undefined">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listMeasure"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label for="unity" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar cual es la unidad básica de medida. "
                                           data-content="Atención, esto campo es muy importante para el catastro y no puede estar equivocado. Si tienes dudas, por favor, haga una consulta a partir del código modelo (transacción MM03, en la primera pantalla)">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right">

                            </div>
                        </div>
                        <div class="row px-0 px-md-5">
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <input class="form-control" id="register3" type="text" maxlength="200"
                                           [disabled]="stepThreeDisabled"
                                           [(ngModel)]="modelRequest.recordHolder" required/>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                           for="register">{{'novocadastro.etapa4.secao2.card3.detentor' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar la operación que realizará el despacho del producto"
                                           data-content="">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <input class="form-control" id="registerMinistry3" maxlength="200" type="text"
                                           [disabled]="stepThreeDisabled"
                                           [(ngModel)]="modelRequest.ministryRecord" required/>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                           for="registerMinistry">{{'novocadastro.etapa4.secao2.card3.registro' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside d-block"
                                           data-toggle="popover"
                                           title="Por  favor,  informar  el  numero  de  registro  del producto"
                                           data-content="Si  el  registro  aún  no  fue  concedido,  llenar  como “Registro aún no concedido”. En esto caso, una vez que se tenga el numero de lo mismo, es necesario informarle  a  Cadastro  Eurofarma,  por  correo,  (Cadastro.Eurofarma@eurofarma.com) el número del código  SAP  del  producto  y  el  numero  del  registro otorgado para que se pueda añadir esa información">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <select id="Dispatched3" class="form-control" name="type"
                                            [disabled]="stepThreeDisabled"
                                            [(ngModel)]="modelRequest.dispatchedBy">
                                        <option disabled selected
                                                value="undefined">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listDispatched"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}" for="Dispatched"
                                           class="mb-0 mr-3">
                                        {{'novocadastro.etapa4.secao2.card3.expedido' | translate}}
                                    </label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar la operación que es propietaria del registro del producto"
                                           data-content="">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 ">

                                &lt;!&ndash;    <div>
                                        <a href="javascript:void('')"  class="d-block" data-toggle="popover" title="Popover title 1" data-content="And here's some amazing content. It's very engaging. Right?4">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>&ndash;&gt;
                            </div>
                        </div>
                        <div class="row flex-lg-row-reverse px-0 px-md-5">
                            <div class="col-md-12 col-lg-6">
                                <div class="input-group input-material mb-3">
                                    <input type="number" class="form-control text-primary"
                                           [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled"
                                           oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                           onchange="if (this.value > 60) this.value = 60"
                                           aria-describedby="basic-addon" maxlength="2">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon">Meses</span>
                                    </div>
                                </div>
                                <div class="input-material d-none">
                                    <select id="Deadline1" class="form-control" name="type"
                                            [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled">
                                        <option disabled selected
                                                [ngValue]="null">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let d of listDueDate" [ngValue]="d">{{d}}
                                            <span *ngIf="d == 1">Mês</span>
                                            <span *ngIf="d !== 1">Meses</span>
                                        </option>
                                    </select>
                                    <label for="Deadline1" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input13' | translate}}</label>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="input-material">
                                    <input class="form-control" id="Manufacturer1" type="text" maxlength="200"
                                           [(ngModel)]="modelRequest.manufacturer" [disabled]="stepThreeDisabled"
                                           required/>
                                    <label for="Manufacturer1" class="col-10 p-0 text-truncate"
                                           [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input7' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            &lt;!&ndash;<a href="javascript:void(0)" (click)="request3()" *ngIf="!stepThreeDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>&ndash;&gt;
                            &lt;!&ndash;<a href="javascript:void(0)" *ngIf="stepThreeDisabled && !btnThreeDisabled"
                               (click)="handlerNext(3)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>&ndash;&gt;
                        </div>
                    </div>
                </div>
-->
                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-3">

                        <div class="col-12">
                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'coloquenumerodecodigodebarras'|translate}}" class="input-material">
                                <input class="form-control" id="EAN" type="text"
                                       (change)="handlerEan13($event)"
                                       (keyup)="handlerEan13($event)"
                                       maxlength="13"
                                       [(ngModel)]="modelRequest.ean"
                                       [disabled]="stepThreeDisabled"
                                       required/>
                                <label for="EAN" [ngClass]="{'disabled': stepThreeDisabled}">EAN 13 *</label>
                            </div>
                            <div class="input-material">
                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'ean13'|translate}}" class="form-control" id="DUM" type="text"
                                       (change)="handlerCharSubscr($event, 14)" (keyup)="handlerCharSubscr($event, 14)"
                                       maxlength="14"
                                       [(ngModel)]="modelRequest.dum"
                                       required [disabled]="true"/>
                                <label for="DUM" [ngClass]="{'disabled': true}">DUM 14 *</label>
                            </div>
                            <!--<div class="input-material">
                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'setemalgumaobservacao'|translate}}" class="form-control" id="unificationObservation" type="text"
                                       maxlength="200"
                                       [(ngModel)]="modelRequest.unificationObservation"
                                       [disabled]="stepThreeDisabled"
                                       required/>
                                <label for="unificationObservation" [ngClass]="{'disabled': stepThreeDisabled}">
                                    {{'novocadastro.etapa4.unificationObservation' | translate}}
                                </label>
                            </div>-->
                        </div>
                    </div>

                </div>

            </div>

            <!--request-four-->
            <div class="tab-pane fade" id="step4" role="tabpanel" aria-labelledby="step4-tab">

                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-3">
                        <div class="col-12">

                            <div class="col-12">
                                <div data-toggle="tooltip"
                                     data-placement="top"
                                     title="NCM" class="input-material">
                                    <input class="form-control" id="ncm" type="text"
                                           maxlength="250"
                                           [(ngModel)]="modelRequest.ncm"
                                           [disabled]="stepFourDisabled"
                                           required/>
                                    <label for="ncm" [ngClass]="{'disabled': stepFourDisabled}">NCM *</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <!--request-five-->
            <div class="tab-pane fade" id="step5" role="tabpanel" aria-labelledby="step5-tab">

                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-3">
                        <div class="col-12">

                            <div class="col-12">
                                <div data-toggle="tooltip"
                                     data-placement="top"
                                     title="{{'coloquenumerodecodigodebarras'|translate}}" class="input-material">
                                    <input class="form-control" id="taxClassification" type="text"
                                           maxlength="250"
                                           [(ngModel)]="modelRequest.taxClassification"
                                           [disabled]="stepFiveDisabled"
                                           required/>
                                    <label for="taxClassification" [ngClass]="{'disabled': stepFiveDisabled}">Classificação
                                        fiscal *</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div data-toggle="tooltip"
                                     data-placement="top"
                                     title="{{'coloquenumerodecodigodebarras'|translate}}" class="input-material">
                                    <input class="form-control" id="groupCtgs" type="text"
                                           maxlength="250"
                                           [(ngModel)]="modelRequest.groupCtgs"
                                           [disabled]="stepFiveDisabled"
                                           required/>
                                    <label for="groupCtgs" [ngClass]="{'disabled': stepFiveDisabled}">Grupo de CTGS
                                        *</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div data-toggle="tooltip"
                                     data-placement="top"
                                     title="{{'coloquenumerodecodigodebarras'|translate}}" class="input-material">
                                    <input class="form-control" id="materialGroup1" type="text"
                                           maxlength="250"
                                           [(ngModel)]="modelRequest.materialGroup1"
                                           [disabled]="stepFiveDisabled"
                                           required/>
                                    <label for="materialGroup1" [ngClass]="{'disabled': stepFiveDisabled}">Grupo de
                                        material 1 *</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <!--request-six-->
            <div class="tab-pane fade" id="step6" role="tabpanel" aria-labelledby="step6-tab">
                <!--<div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material question">

                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'escrevaseelancamentoou'|translate}}" class="form-control" id="request3" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.motive"
                                       required [disabled]="stepThreeDisabled"/>
                                <label for="request"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input1' | translate}}</label>

                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>

                                    </a>
                                </div>
                            </div>

                            <div class="input-material question col-lg-12 mr-3">
                                &lt;!&ndash;<input class="form-control js-daterangepicker" id="validity1" type="text" maxlength="10"
                                       [disabled]="stepThreeDisabled" required/>
                                <label for="validity1"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa4.secao2.card3.validade' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop7.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>&ndash;&gt;

                                <input data-toggle="tooltip"
                                       data-placement="top"
                                       title="{{'informarmesesdevidautil'|translate}}" type="number" class="form-control text-primary" min="0" id="dueDateR"
                                       [(ngModel)]="modelRequest.dueDateRevised" [disabled]="stepThreeDisabled"
                                       oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                       onchange="if (this.value > 60) this.value = 60"
                                       aria-describedby="basic-addon2" maxlength="2">
                                <label for="dueDateR"
                                       class="text-truncate" style="max-width: 75%"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa3.inputDeadline' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside_right d-block"
                                       data-toggle="popover"
                                       title="{{'novocadastro.etapa3.inputDeadline' | translate}}"
                                       [attr.data-content]="getTranslateInstantKey('step2.p1')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'portaria344'|translate}}" class="input-material disabled question mt-5" *ngIf="!stepThreeDisabled">
                                <select class="form-control" [(ngModel)]="modelDescriptionPortaria"
                                        (change)="setModelPortariaDescription()" [disabled]="stepThreeDisabled"
                                        required>
                                    <option value="">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listPort" [ngValue]="c.name">{{c.description}}</option>
                                </select>
                                <label>Portaria 344/98</label>
                                <div>
                                    <a href="javascript:void('')" class="d-block question_beside_right"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step3.pop1.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step3.pop1.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>


                            <div class="modal-body modal-body&#45;&#45;small pt-0">
                                <div class="row flex-column">
                                    <div class="text-center mx-auto font-weight-bold text-primary mt-0 mb-5 size-lg">
                                        Verificação
                                    </div>
                                    <div class="bg-beige rounded pt-3 pb-1 px-10">
                                        <div class="bg-beige rounded pt-3 pb-1 px-4">
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch100" [(ngModel)]="isChecked1">
                                                    <label class="custom-control-label" for="customSwitch100">
                                                        {{'popovers.step3.check1' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch101" [(ngModel)]="isChecked2">
                                                    <label class="custom-control-label" for="customSwitch101">
                                                        {{'popovers.step3.check2' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="input-material d-flex align-items-center"
                                                 style="min-height: 85px">
                                                <div class="custom-control custom-switch w-100">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="customSwitch102" [(ngModel)]="isChecked3">
                                                    <label class="custom-control-label" for="customSwitch102">
                                                        {{'popovers.step3.check3' | translate}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="text-left mb-2">
                                <p class="font-weight-bold"><span
                                        class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span></p>
                                <p>{{'step2.texto1' | translate}}</p>
                            </div>
                            <input class="form-control" id="descriptionIptStep3" [(ngModel)]="modelRequest.description"
                                   [readOnly]="false" type="text" maxlength="33" required/>

                            <div class="w-100 d-block mb-4 mt-4"
                                 *ngIf="!stepThreeDisabled">
                                <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                   class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                            </div>
                        </div>

                    </div>
                    <div class="d-none">
                        <div class="row px-0 px-md-5">
                            <div class="col-md-6">

                                <div class="input-material question">
                                    <select id="channel3" class="form-control" name="type"
                                            [(ngModel)]="modelRequest.channelDistribution"
                                            [disabled]="stepThreeDisabled">
                                        <option disabled selected value="undefined">Selecione</option>
                                        <option *ngFor="let c of listChannel"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label for="channel" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input2' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor,  elegir el canal donde será distribuido el producto"
                                           data-content="Generalmente, para operaciones internacionales, el canal correcto es subsidiarias">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>

                                </div>
                                <div class="input-material question">
                                    <select id="unity3" class="form-control" name="unity"
                                            [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepThreeDisabled">
                                        <option disabled selected
                                                value="undefined">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listMeasure"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label for="unity" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar cual es la unidad básica de medida. "
                                           data-content="Atención, esto campo es muy importante para el catastro y no puede estar equivocado. Si tienes dudas, por favor, haga una consulta a partir del código modelo (transacción MM03, en la primera pantalla)">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right">

                            </div>
                        </div>
                        <div class="row px-0 px-md-5">
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <input class="form-control" id="register3" type="text" maxlength="200"
                                           [disabled]="stepThreeDisabled"
                                           [(ngModel)]="modelRequest.recordHolder" required/>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                           for="register">{{'novocadastro.etapa4.secao2.card3.detentor' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar la operación que realizará el despacho del producto"
                                           data-content="">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <input class="form-control" id="registerMinistry3" maxlength="200" type="text"
                                           [disabled]="stepThreeDisabled"
                                           [(ngModel)]="modelRequest.ministryRecord" required/>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                           for="registerMinistry">{{'novocadastro.etapa4.secao2.card3.registro' | translate}}</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside d-block"
                                           data-toggle="popover"
                                           title="Por  favor,  informar  el  numero  de  registro  del producto"
                                           data-content="Si  el  registro  aún  no  fue  concedido,  llenar  como “Registro aún no concedido”. En esto caso, una vez que se tenga el numero de lo mismo, es necesario informarle  a  Cadastro  Eurofarma,  por  correo,  (Cadastro.Eurofarma@eurofarma.com) el número del código  SAP  del  producto  y  el  numero  del  registro otorgado para que se pueda añadir esa información">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material question">
                                    <select id="Dispatched3" class="form-control" name="type"
                                            [disabled]="stepThreeDisabled"
                                            [(ngModel)]="modelRequest.dispatchedBy">
                                        <option disabled selected
                                                value="undefined">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let c of listDispatched"
                                                [ngValue]="c.name">{{c.description}}</option>
                                    </select>
                                    <label [ngClass]="{'disabled' : stepThreeDisabled}" for="Dispatched"
                                           class="mb-0 mr-3">
                                        {{'novocadastro.etapa4.secao2.card3.expedido' | translate}}
                                    </label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside_right d-block"
                                           data-toggle="popover"
                                           title="Por favor, informar la operación que es propietaria del registro del producto"
                                           data-content="">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 ">

                                &lt;!&ndash;    <div>
                                        <a href="javascript:void('')"  class="d-block" data-toggle="popover" title="Popover title 1" data-content="And here's some amazing content. It's very engaging. Right?4">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>&ndash;&gt;
                            </div>
                        </div>
                        <div class="row flex-lg-row-reverse px-0 px-md-5">
                            <div class="col-md-12 col-lg-6">
                                <div class="input-group input-material mb-3">
                                    <input type="number" class="form-control text-primary"
                                           [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled"
                                           oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                           onchange="if (this.value > 60) this.value = 60"
                                           aria-describedby="basic-addon" maxlength="2">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon">Meses</span>
                                    </div>
                                </div>
                                <div class="input-material d-none">
                                    <select id="Deadline1" class="form-control" name="type"
                                            [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled">
                                        <option disabled selected
                                                [ngValue]="null">{{'general.selecione' | translate}}</option>
                                        <option *ngFor="let d of listDueDate" [ngValue]="d">{{d}}
                                            <span *ngIf="d == 1">Mês</span>
                                            <span *ngIf="d !== 1">Meses</span>
                                        </option>
                                    </select>
                                    <label for="Deadline1" [ngClass]="{'disabled' : stepThreeDisabled}"
                                           class="mb-0 mr-3">{{'novocadastro.etapa2.input13' | translate}}</label>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="input-material">
                                    <input class="form-control" id="Manufacturer1" type="text" maxlength="200"
                                           [(ngModel)]="modelRequest.manufacturer" [disabled]="stepThreeDisabled"
                                           required/>
                                    <label for="Manufacturer1" class="col-10 p-0 text-truncate"
                                           [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input7' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            &lt;!&ndash;<a href="javascript:void(0)" (click)="request3()" *ngIf="!stepThreeDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>&ndash;&gt;
                            &lt;!&ndash;<a href="javascript:void(0)" *ngIf="stepThreeDisabled && !btnThreeDisabled"
                               (click)="handlerNext(3)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>&ndash;&gt;
                        </div>
                    </div>
                </div>
-->
                <div class="mx-auto p-4 tab-pane-content">
                    <div class="row mt-3">

                        <div class="col-12">
                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'somenteProdutoCompradoPronto'|translate}}" class="input-material">
                                <input class="form-control" id="cst" type="number" inputmode="numeric"
                                       maxlength="250"
                                       [(ngModel)]="modelRequest.cst"
                                       [disabled]="stepSixDisabled"
                                       required/>
                                <label for="cst" [ngClass]="{'disabled': stepSixDisabled}">{{'codigoOrigemCST'|translate}} *</label>
                            </div>
                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'somenteProdutoCompradoPronto'|translate}}" class="input-material">
                                <input class="form-control" id="originalCountry" type="text"
                                       maxlength="250"
                                       [(ngModel)]="modelRequest.originalCountry"
                                       [disabled]="stepSixDisabled"
                                       required/>
                                <label for="originalCountry" [ngClass]="{'disabled': stepSixDisabled}">{{ 'paisOrigem' | translate }} *</label>
                            </div>
                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'somenteProdutoCompradoPronto'|translate}}" class="input-material">
                                <input class="form-control" id="priceReais" type="text"
                                       maxlength="250"
                                       [(ngModel)]="modelRequest.priceReais"
                                       [disabled]="stepSixDisabled"
                                       required/>
                                <label for="priceReais" [ngClass]="{'disabled': stepSixDisabled}">{{ 'precoReaisSemImpostos' | translate }} *</label>
                            </div>
                            <div data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'somenteProdutoCompradoPronto'|translate}}" class="input-material">
                                <input class="form-control" id="leadTime" type="number" inputmode="numeric"
                                       maxlength="250"
                                       [(ngModel)]="modelRequest.leadTime"
                                       [disabled]="stepSixDisabled"
                                       required/>
                                <label for="leadTime" [ngClass]="{'disabled': stepSixDisabled}">{{ 'leadDias' | translate }} *</label>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!--request-seven-->
            <div class="tab-pane fade" id="step7" role="tabpanel" aria-labelledby="step7-tab">
                <div class="row mt-4">

                    <div class="col-md-7">
                        <div class="px-3 py-4" style="max-height: 464px;overflow: auto;">
                            <div class="card bg-warning border-0 shadow-none">
                                <div class="row p-3">
                                    <strong class="col size-md">{{ 'novocadastro.etapa4.secao2.card1.criado' | translate }}</strong>
                                    <span class="col text-right size-md">{{ modelRequest.createdAt | date: 'dd/MM/yyyy' }}</span>
                                    <span class="col-12 size-xm pl-4">{{ modelRequest.createdBy?.name }}</span>
                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">{{ 'novocadastro.etapa1.title' | translate }}</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{ 'general.reasonRequest' | translate }}:
                                                <span class="text-primary">{{ (modelRequest.reasonRequest === 0 ? 'general.launch' : 'general.newSendBulk') | translate }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input2' | translate }}:
                                                <span class="text-primary">{{ modelRequest.channelDistribution }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input3' | translate }}:
                                                <span class="text-primary">{{ modelRequest.unitMeasure }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input8' | translate }}:
                                                <span class="text-primary">{{ modelRequest.description }}</span>
                                            </span>

                                            <div class="col-12 mt-2">
                                                <span>{{ 'general.countryRequest' | translate }}:
                                                    <span class="text-primary"
                                                          *ngFor="let z of modelRequest.countryNotifications">{{ getCountryNameByKey(z) }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">AR Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <!--COLUMN-->
                                            <div class="col-12 col-md-6 pr-md-4">
                                                <div class="mt-2">{{ 'novocadastro.etapa4.secao2.card3.detentor' | translate }}
                                                    <span class="text-primary">{{ modelRequest.recordHolder }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'expedidoPor' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.dispatchedBy }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'step4.sidecard.Arlocal.registro' | translate }}
                                                    <span class="text-primary">{{ modelRequest.ministryRecord }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'step4.sidecard.Arlocal.validade' | translate }}
                                                    <span class="text-primary">{{ modelRequest.recordValidity | date: 'dd/MM/yyyy' }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'novocadastro.etapa2.input7' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.manufacturer }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'novocadastro.etapa2.input2' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.channelDistribution }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'descritivo' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.description }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'novocadastro.etapa2.input3' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.unitMeasure }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'fabricante' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.manufacturer }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 1:
                                                    <span class="text-primary">{{ modelRequest.concentrationPrinciple1 }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 2:
                                                    <span class="text-primary">{{ modelRequest.concentrationPrinciple2 }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 3:
                                                    <span class="text-primary">{{ modelRequest.concentrationPrinciple3 }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 4:
                                                    <span class="text-primary">{{ modelRequest.concentrationPrinciple4 }}</span>
                                                </div>
                                                <div class="d-flex justify-content-between gap-20">
                                                    <div class="mt-2 w-40 flex-grow-1">DCB 1:
                                                        <span class="text-primary">{{ modelRequest.dcb1 }}</span>
                                                    </div>
                                                    <div class="mt-2 w-40 flex-grow-1">DCB 2:
                                                        <span class="text-primary">{{ modelRequest.dcb2 }}</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-between gap-20">
                                                    <div class="mt-2 w-40 flex-grow-1">DCB 3:
                                                        <span class="text-primary">{{ modelRequest.dcb3 }}</span>
                                                    </div>
                                                    <div class="mt-2 w-40 flex-grow-1">DCB 4:
                                                        <span class="text-primary">{{ modelRequest.dcb4 }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--COLUMN-->
                                            <div class="col-12 col-md-6 pl-md-4">
                                                <div class="mt-2">{{ 'anvisaClassificacao' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.anvisaClassification }}</span>
                                                </div>
                                                <div class="mt-2">{{'concentracaoFormaQuantidade' | translate}}:
                                                    <span class="text-primary">{{ modelRequest.productPresentation }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'codigoClasseTerapeutica' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.therapyClass }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'formaAplicacao' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.applicationType }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'medicamentoReferencia' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.referenceMed }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'unidadeDose' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.doseUnit }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'formaFarmaceutica' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.pharmForm }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'tarjaProduto' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.productStripe }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'condicaoArmazenagem' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.storageCondition }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'prazoValidadeMeses' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.expirationDate }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'apresentacaoProdutoOriginal' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.originalProductPresentation }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'vendaPrescricaoMedica' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.medicalPrescription }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'setorAtividade' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.sectorOfActivity }}</span>
                                                </div>
                                                <div class="mt-2">{{ 'tipoProduto' | translate }}:
                                                    <span class="text-primary">{{ modelRequest.productType }}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Artes</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">EAN 13:
                                                <span class="text-primary">{{ modelRequest.ean }}</span>
                                            </span>
                                            <span class="col-12 mt-2">DUM 14:
                                                <span class="text-primary">{{ modelRequest.dum }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <!--<strong class="col-12 mt-3 mb-2">{{ 'general.registerUnify' | translate }}</strong>
                                    <div class="col-12">
                                        <div class="card p-2 pl-4 mt-3 pl-sm-3"
                                             *ngFor="let u of modelRequest.group">
                                            <div class="row">
                                                <div class="col-sm-8 ml-0 mt-3 mt-sm-0">
                                                    <div class="row">
                                                        <span class="col-12 text-gray-text">{{ u.country }}</span>
                                                        <span class="col-12 text-gray-text">{{ 'novocadastro2.descritivo' | translate }}
                                                            : <strong>{{ u.description }}</strong></span>
                                                        <span class="col-12 text-gray-text">{{ 'novocadastro.etapa2.input13' | translate }}
                                                            :
                                                            <strong>{{ u.dueDate }} meses</strong></span>
                                                    </div>

                                                </div>
                                                <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                    <div class="row text-center">
                                                        <span class="col-12 text-gray-text size-xs">{{ 'novocadastro.etapa4.card1.prenchido' | translate }}
                                                            até</span>
                                                        <span class="col-12 d-flex align-items-center justify-content-center text-primary">
                                                            <span class="d-flex justify-content-center align-items-center bg-warning rounded-circle mr-2"
                                                                  style="width: 25px;height: 25px;">
                                                                <strong>{{ u.step }}</strong>
                                                            </span>
                                                            <strong>AR Interinacional</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Gamal (NCM)</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{ modelRequest.ncm }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">{{ 'tributario' | translate }}</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{ 'classificacaoFiscal' | translate }}
                                                <span class="text-primary">{{ modelRequest.taxClassification }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'grupoCtgs' | translate }}
                                                <span class="text-primary">{{ modelRequest.groupCtgs }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'grupoMaterial' | translate }}
                                                <span class="text-primary">{{ modelRequest.materialGroup1 }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">GLP</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{ 'codigoOrigemCST' | translate }}
                                                <span class="text-primary">{{ modelRequest.cst }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'paisOrigem' | translate }}
                                                <span class="text-primary">{{ modelRequest.originalCountry }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'precoReaisSemImpostos' | translate }}
                                                <span class="text-primary">{{ modelRequest.priceReais }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'leadDias' | translate }}
                                                <span class="text-primary">{{ modelRequest.leadTime }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-5">
                        <div class="pl-5 my-4 pt-2 pr-5 border-left border-gray-text">
                            <div class="bg-success text-white rounded d-flex justify-content-center px-2 mb-2"
                                 *ngIf="modelRequest.status == 'approve'" style="width: fit-content">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                <span class="ml-1">{{ 'minhalista.concluido' | translate }}</span>
                            </div>

                            <ng-container *ngIf="modelRequest.status == 'disapprove'">
                                <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                                     style="width: fit-content">
                                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                                    <span class="ml-1">{{ 'minhalista.reprovado' | translate }}</span>
                                </div>
                                <div class="mt-3 mb-4">
                                    <p><strong>{{ 'general.step' | translate }}
                                        :</strong> {{ getNameStep(modelRequest.stepReprove) }}</p>
                                    <p><strong>{{ 'general.justify' | translate }}
                                        :</strong> {{ modelRequest.descriptionFinish }}</p>
                                </div>
                            </ng-container>

                            <div class="mb-4">
                                <a href="javascript:void('');" class="btn btn-block btn-primary" (click)="getExcel()">Exportar</a>
                            </div>

                            <ng-container *ngIf="modelRequest.status !== 'disapprove'">
                                <div class="input-material">
                                    <input class="form-control" id="SAP" type="text" maxlength="10"
                                           [disabled]="stepSixDisabled"
                                           [(ngModel)]="modelRequest.codeSap"
                                           required/>
                                    <label for="SAP"
                                           [ngClass]="{'disabled': stepSixDisabled}">{{ 'general.codeSAPCreate' | translate }}</label>
                                </div>
                                <!--                                <div class="input-material">-->
                                <!--                                    <input class="form-control" id="code400" type="text" maxlength="100"-->
                                <!--                                           [disabled]="stepFiveDisabled"-->
                                <!--                                           [(ngModel)]="modelRequest.code_400"-->
                                <!--                                           required/>-->
                                <!--                                    <label for="code400"-->
                                <!--                                           [ngClass]="{'disabled' : stepOneDisabled}">{{'general.codigo400' | translate}}*</label>-->
                                <!--                                </div>-->
                                <!--                                <div class="input-material">-->
                                <!--                                    <input class="form-control" id="motive400" type="text" maxlength="100"-->
                                <!--                                           [disabled]="stepFiveDisabled"-->
                                <!--                                           [(ngModel)]="modelRequest.motive_400"-->
                                <!--                                           required/>-->
                                <!--                                    <label for="motive400"-->
                                <!--                                           [ngClass]="{'disabled' : stepOneDisabled}">{{'general.motive400' | translate}}*</label>-->
                                <!--                                </div>-->
                            </ng-container>
                            <ng-container
                                    *ngIf="modelRequest.status == 'open' || isNullOrUndefined(modelRequest.status)">
                                <a href="javascript:void(0)" (click)="requestFinish('approve')"
                                   class="btn btn-block btn-warning mt-5"
                                   type="button">{{ 'novocadastro2.aprovar' | translate }}</a>
                                <a (click)="openModal()"
                                   class="btn btn-block btn-outline-primary mt-3"
                                   type="button">{{ 'novocadastro2.reprovar' | translate }}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <ng-container *ngIf="modelRequest.status == 'disapprove' && (step === modelRequest.step && step < 7)">
            <div class="d-flex flex-column align-items-center justify-content-center mb-4">
                <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                     style="width: fit-content">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                    <span class="ml-1">{{ 'minhalista.reprovado' | translate }}</span>
                </div>
                <div class="mt-3 mb-4">
                    <p><strong>{{ 'general.step' | translate }}
                        :</strong>{{ getNameStep(modelRequest.stepReprove) }}</p>
                    <p><strong>{{ 'general.justify' | translate }}
                        :</strong> {{ modelRequest.descriptionFinish }}</p>
                </div>
            </div>
        </ng-container>

        <div class="col-12 mb-4 text-right"
             *ngIf="step > 1 && step < 7 && stepTabSend && (modelRequest.status === 'open' || isNullOrUndefined(modelRequest.status))">
            <a (click)="openModal()"
               class="btn btn-outline-danger my-3"
               type="button">{{ 'novocadastro2.reprovar' | translate }}</a>
        </div>

        <div class="col-12 mx-auto mb-4" style="max-width: 400px"
             *ngIf="modelRequest.status !== 'disapprove' && modelRequest.status !== 'approve'">
            <a href="javascript:void(0)" (click)="actionSendTab()"
               *ngIf="step < 7 && (stepTabSend && (modelRequest.status === 'open' || isNullOrUndefined(modelRequest.status)))"
               class="btn btn-block btn-warning"
               type="button">{{ 'novocadastro.enviar' | translate }}</a>
            <a href="javascript:void(0)" (click)="actionNextTab()" *ngIf="stepTabNext"
               class="btn btn-block btn-warning"
               type="button">{{ 'novocadastro.next' | translate }}</a>
        </div>

    </div>

    <!--step == 4 || step == 5-->
    <div class="bg-beige px-3 py-4" *ngIf="false"
         style="width: 100%;min-width: 40%;overflow: auto;min-height: 709px;">
        <div class="card bg-warning border-0 shadow-none">
            <div class="row p-3">
                <strong class="col size-md">Criado por</strong>
                <span class="col text-right size-md">{{ modelRequest.createdAt | date: 'dd/MM/yyyy' }}</span>
                <span class="col-12 size-xm pl-4">{{ modelRequest.createdBy?.name }}</span>
            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">Novos Negócios</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <!--<span class="col-12 mt-2">{{'step4.sidecard.comercial-local.nome' | translate}}
                            <span class="text-primary">{{modelRequestResult.name}}</span>
                        </span>-->
                        <span class="col-12 mt-2">{{ 'general.codigomodelo' | translate }}
                            <span class="text-primary">{{ modelRequestResult.code }}</span>
                        </span>
                        <div class="col-12 mt-2">
                            <span> {{ 'general.countryRequest' | translate }}
                                <span class="text-primary d-inline-block mr-2"
                                      *ngFor="let x of modelRequestResult.countryNotifications">{{ getCountryNameByKey(x) }}</span>
                            </span>
                            <!--<img class="mr-2" width="30"
                                 src="assets/images/country/{{handlerFlagImage(modelRequestResult.country)}}" alt="country"/>
                            <span>{{'step4.sidecard.comercial-local.pais' | translate}}
                                <span class="text-primary">{{modelRequestResult.country}}</span>
                            </span>-->
                        </div>

                        <span class="col-12 mt-2">{{ 'centroProducao' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.centerModel }}</span>

                        <span class="col-12 mt-2">{{ 'canalDistribuicaoCodigoModelo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.channelModel }}</span>

                        <span class="col-12 mt-2">{{ 'motivoSolicitacao' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.reasonRequestInfo }}</span>

                        <span class="col-12 mt-2">{{ 'tipoMaterial' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.typeMaterial }}</span>

                        <span class="col-12 mt-2">{{ 'centro' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.center }}</span>

                        <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input2' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.channelDistribution }}</span>

                        <span class="col-12 mt-2">{{ 'descritivo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.description }}</span>

                        <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input3' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.unitMeasure }}</span>

                        <span class="col-12 mt-2">{{ 'novocadastro.etapa4.secao2.card3.detentor' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.recordHolder }}</span>

                        <span class="col-12 mt-2">{{ 'expedidoPor' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.dispatchedBy }}</span>

                        <span class="col-12 mt-2">{{ 'fabricante' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.manufacturer }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 1</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple1 }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 2</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple2 }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 3</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple3 }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 4</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple4 }}</span>

                    </div>
                </div>

            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">AR Brasil</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.motivo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.motive }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.canal' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.channelDistribution }}</span>
                        <span class="col-12 mt-2">{{ 'novocadastro2.descritivo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.description }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.unidade' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.unitMeasure }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.detentor' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.recordHolder }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.expedido' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.dispatchedBy }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.registro' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.ministryRecord }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.validade' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.recordValidity | date: 'dd/MM/yyyy' }}</span>
                        <!--<span class="col-12 mt-2">{{'step4.sidecard.Arlocal.fabricante' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.manufacturer}}</span>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Modal modalRegisterJustify -->
<div class="modal-custom modal fade" id="modalRegisterJustify" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRegisterJustifyLabel"
     aria-hidden="true"

>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{ 'general.justifyArea' | translate }}
                    </div>

                    <div class="bg-beige rounded pt-3 pb-1 px-4">
                        <div class="input-material mb-1">
                            <select class="form-control" [(ngModel)]="modelRequest.stepReprove">
                                <option [ngValue]="undefined">Selecione</option>
                                <ng-container *ngFor="let x of [1,2,3,4,5,6];">
                                    <option *ngIf="x < modelRequest.step +1" [ngValue]="x">{{ x }}
                                        - {{ getNameStepByNumber(x) }}
                                    </option>
                                </ng-container>
                            </select>
                            <label class="mb-0 mr-3">Etapa</label>
                        </div>
                    </div>
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{ 'general.justifyReprove' | translate }}
                    </div>
                    <div class="py-3 bg-beige rounded pb-5 px-4">
                        <div class="text-left mb-2">
                            <span class="text-secondary">{{ 'novocadastro2.descritivo' | translate }}</span>
                        </div>
                        <textarea class="input-material mb-0" id="description3"
                                  style="width: 100%; resize: none"
                                  rows="7" [(ngModel)]="modelRequest.descriptionFinish"
                                  maxlength="900" required></textarea>
                        <label class=" w-100 text-right" for="description3"
                        >
                            {{ 'novocadastro.maximo1' | translate }}
                            {{ handlerMaximumCharaters(this.modelRequest.descriptionFinish) }}
                            {{ 'novocadastro.maximo2' | translate }}</label>
                    </div>
                    <a href="javascript:void('');" class="btn btn-block btn-primary mt-5"
                       (click)="requestFinish('disapprove')">Enviar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Description Form-->
<div class="modal-custom modal fade" id="modalDescriptionForm" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalDescriptionFormLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="subTitle text-gray-text">{{ 'modal.title' | translate }}</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small p-0">
                <div class="row mx-0 flex-column">
                    <div class="py-3 rounded pb-5 px-0 px-xs-4">

                        <div class="nav_step-modal shadow mx-auto mb-4 position-relative">
                            <div class="text-right m-auto description">
                                <div class="text-center mx-auto font-weight-bold text-primary primary"
                                     style="height: 48px;">
                                    {{ getDescriptionString() }}
                                </div>
                                <hr class="m-0">
                                <small class="text-right"
                                       *ngIf="((getDescriptionString().split('').length - 3) - 33) * (-1) >= 0">Restam {{ ((getDescriptionString().split('').length - 3) - 33) * (-1) }}
                                    caracteres.</small>
                                <small class="text-right"
                                       *ngIf="((getDescriptionString().split('').length - 3) - 33) * (-1) < 0">{{ 'modal.alert.quartenary' | translate }}</small>
                            </div>

                            <div class="position-relative" style="top: 10px;">
                                <ul id="mytabF-modal" role="tablist" class="nav d-flex justify-content-between">
                                    <li class="text-center">
                                        <a id="step1-tabF-modal" data-toggle="tab" href="#step1-modal" role="tab"
                                           aria-controls="step1-modal"
                                           aria-selected="true" class="nav-link-modal nav_step-secondary-item active">
                                            <span class="step">1</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step1.label' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step2-tabF-modal" data-toggle="tab" href="#step2-modal" role="tab"
                                           aria-controls="step2-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">2</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step2.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step3-tabF-modal" data-toggle="tab" href="#step3-modal" role="tab"
                                           aria-controls="step3-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">3</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step3.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step4-tabF-modal" data-toggle="tab" href="#step4-modal" role="tab"
                                           aria-controls="step4-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">4</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step4.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step5-tabF-modal" data-toggle="tab" href="#step5-modal" role="tab"
                                           aria-controls="step5-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">5</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step5.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step6-tabF-modal" data-toggle="tab" href="#step6-modal" role="tab"
                                           aria-controls="step6-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">6</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step6.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step7-tabF-modal" data-toggle="tab" href="#step7-modal" role="tab"
                                           aria-controls="step7-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">7</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step7.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step8-tabF-modal" data-toggle="tab" href="#step8-modal" role="tab"
                                           aria-controls="step8-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">8</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step8.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr class="bg-primary w-90 mx-auto">

                        <div class="col-12 col-xl-10 mx-auto mt-4">
                            <div class="tab-content" id="myTabContent-modal">
                                <!--request-one-->
                                <div class="tab-pane fade show active" id="step1-modal" role="tabpanel"
                                     aria-labelledby="step1-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step1.label' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6">
                                            <p><strong> {{ 'modal.step1.title1' | translate }}</strong></p>
                                            <p>{{ 'modal.step1.text1' | translate }}</p>
                                            <p><strong> {{ 'modal.step1.title2' | translate }}</strong></p>
                                            <p> {{ 'modal.step1.text2' | translate }}</p>
                                            <p><strong> {{ 'modal.step1.exemple' | translate }}</strong></p>
                                            <p><b class="yellow_back">Alendronato</b> de sódio = <b class="yellow_back">ALENDRONATO</b>
                                                SOD</p>
                                            <p>Cloridrato de <b class="yellow_back">amitriptilina</b> = <b
                                                    class="yellow_back">AMITRIPTLINA</b> CLOR</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control text-uppercase" maxlength="200"
                                                           type="text"
                                                           [(ngModel)]="modelDescription.step0" required/>
                                                    <label>{{ 'modal.step1.label' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-two-->
                                <div class="tab-pane fade" id="step2-modal" role="tabpanel"
                                     aria-labelledby="step2-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step2.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{ 'modal.step2.text' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-7">
                                            <div class="bg-beige p-2 px-1 px-xs-3 rounded">
                                                <div class="input-material mt-4">
                                                    <input class="form-control" maxlength="200" type="text"
                                                           [ngModel]="modelDescription.step1"
                                                           (ngModelChange)="handlerBlockLetters($event,'step1')"
                                                           appOnlyLetters
                                                           required/>
                                                    <label>{{ 'modal.step2.label2' | translate }}</label>
                                                </div>
                                                <div class="input-material align-items-center d-flex"
                                                     *ngIf="!isNullOrUndefined(modelDescription.step1)">
                                                    <div class="w-100">
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'%'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">%</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MG'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MG</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MCG'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MCG</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'G'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">G</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'ML'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">ML</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'L'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">L</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'UI'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">UI</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MG/ML'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MG/ML</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr/>

                                                <div class="custom-control custom-switch custom-switch-single">
                                                    <input type="checkbox" id="step1ConcAct2"
                                                           class="custom-control-input"
                                                           (ngModelChange)="actionisChangeDescriptionConcentration()"
                                                           [value]="false"
                                                           [(ngModel)]="modelDescription.step1isB"
                                                    />
                                                    <label for="step1ConcAct2"
                                                           class="custom-control-label">{{ 'modal.step2.label3' | translate }}</label>
                                                </div>
                                                <ng-container *ngIf="modelDescription.step1isB">
                                                    <div class="input-material mt-4">
                                                        <input class="form-control" maxlength="200" type="text"
                                                               [(ngModel)]="modelDescription.step1B"
                                                               (ngModelChange)="handlerBlockLetters($event,'step1B')"
                                                               appOnlyLetters
                                                               required/>
                                                        <label>{{ 'modal.step2.label3' | translate }}</label>
                                                    </div>
                                                    <div class="input-material align-items-center d-flex"
                                                         *ngIf="!isNullOrUndefined(modelDescription.step1B)">
                                                        <div class="w-100">
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'%'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">%</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MG'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MG</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MCG'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MCG</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'G'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">G</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'ML'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">ML</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'L'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">L</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'UI'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">UI</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MG/ML'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MG/ML</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!--request-three-->
                                <div class="tab-pane fade" id="step3-modal" role="tabpanel"
                                     aria-labelledby="step3-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step3.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6 offset-lg-3">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="register__checkbox">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="terms2" class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step2"/>
                                                        <label for="terms2"
                                                               class="custom-control-label">{{ 'modal.step3.text' | translate }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-four-->
                                <div class="tab-pane fade" id="step4-modal" role="tabpanel"
                                     aria-labelledby="step4-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step4.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6">
                                            <p>{{ 'modal.step4.text' | translate }}</p>
                                            <p><b>{{ 'modal.step4.exemplo' | translate }}</b></p>
                                            <p>{{ 'modal.step4.text2' | translate }}</p>
                                            <p>{{ 'modal.step4.text3' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control" maxlength="200" type="number"
                                                           [(ngModel)]="modelDescription.step3"
                                                           required/>
                                                    <label>{{ 'modal.step4.label' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-five-->
                                <div class="tab-pane fade" id="step5-modal" role="tabpanel"
                                     aria-labelledby="step5-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step5.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{ 'modal.step5.text' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <select class="form-control" (change)="handlerListIsBulk()"
                                                            [(ngModel)]="modelDescription.step4">
                                                        <option value="">Selecione</option>
                                                        <option *ngFor="let p of listPresentation"
                                                                [ngValue]="p.name">{{ p.description }}
                                                        </option>
                                                    </select>
                                                    <label>{{ 'modal.step5.title' | translate }}</label>
                                                </div>
                                                <div class="register__checkbox"
                                                     *ngIf="modelDescription.step4 === 'CAP' || modelDescription.step4 === 'CPR'">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="step4isBulk"
                                                               class="custom-control-input"
                                                               (change)="bulkStateToogle()"
                                                               [checked]="modelDescription.step4isBulk"
                                                               [(ngModel)]="modelDescription.step4isBulk"
                                                        />
                                                        <label for="step4isBulk"
                                                               class="custom-control-label">{{ 'general.bulk' | translate }}</label>
                                                    </div>
                                                </div>
                                                <!--<div class="register__checkbox mt-5">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="step5isSpace"
                                                               class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step5isSpace"
                                                        />
                                                        <label for="step5isSpace"
                                                               class="custom-control-label">{{'general.spacing' | translate}}</label>
                                                    </div>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-sixe-->
                                <div class="tab-pane fade" id="step6-modal" role="tabpanel"
                                     aria-labelledby="step6-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step6.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{ 'modal.step6.text' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control" maxlength="200" type="text"
                                                           [(ngModel)]="modelDescription.step5"
                                                           required/>
                                                    <label>{{ 'modal.step6.title' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-seven-->
                                <div class="tab-pane fade" id="step7-modal" role="tabpanel"
                                     aria-labelledby="step7-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step7.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6 offset-lg-3">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="register__checkbox">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" id="muestra" class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step6"
                                                        />
                                                        <label for="muestra"
                                                               class="custom-control-label">{{ 'modal.step7.muestra' | translate }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-eight-->
                                <div class="tab-pane fade" id="step8-modal" role="tabpanel"
                                     aria-labelledby="step8-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step8.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-12">
                                            <p>{{ 'modal.step8.text' | translate }}</p>
                                        </div>
                                        <div class="col-12">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="assign-team my-4">
                                                    <div>
                                                        <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                                           *ngFor="let n of modelDescriptionCountry"
                                                           href="javascript:void('');"
                                                           (click)="handlerSelectedCountryDescription(n.name, n.description)"
                                                           [ngClass]="{'active' : n.name === modelDescription.step7 || (n.name === 'CHI' && modelDescription.step7 === 'CH')}">
                                                            <!--<svg-icon src="assets/images/country/{{n.thumb}}"></svg-icon>-->
                                                            <img src="assets/images/country/{{n.thumb}}" alt="" />
                                                            <span>{{ n.name === 'CHI' ? 'CH' : n.name }}</span>
                                                        </a>
                                                        <!--<a href="javascript:void('');" (click)="modelDescription.step7 = 'ARG'">
                                                            <img class="rounded-circle avatar-sm" alt="64x64"
                                                                src="assets/images/">
                                                        </a>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mx-auto mt-1 mt-xs-5 d-flex justify-content-around w-75">
                        <!--<a href="javascript:$('#modalDescriptionForm').modal('hide')"
                           class="btn btn-warning mx-2"
                           type="button">Fechar</a>-->

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep > 0"
                           (click)="prevStepModalDescription()"
                           class="btn btn-outline-primary px-1 px-xs-5"
                           type="button">{{ 'back' | translate }}</a>

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep < 7"
                           (click)="nextStepModalDescription()"
                           class="btn btn-warning px-1 px-xs-5"
                           type="button">Próximo</a>

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep === 7"
                           (click)="finishStepModalDescription()"
                           class="btn btn-warning px-1 px-xs-5"
                           type="button">Finalizar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal BACK STEP -->
<div class="modal-custom modal fade" id="modalBackStep" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRegisterJustifyLabel"
     aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{ 'general.resetRequestText' | translate }}
                    </div>
                    <div class="bg-beige rounded pt-3 pb-1 px-4">
                        <div class="input-material mb-1">
                            <select class="form-control" [(ngModel)]="modelResetStep.step">
                                <option [ngValue]="undefined">Selecione</option>
                                <ng-container *ngFor="let x of [1,2,3,4,5,6];">
                                    <option *ngIf="x < modelRequest.step" [ngValue]="x - 1">{{ x }}
                                        - {{ getNameStepByNumber(x) }}
                                    </option>
                                </ng-container>
                            </select>
                            <label class="mb-0 mr-3">Etapa</label>
                        </div>
                    </div>
                    <div class="py-3 bg-beige rounded pb-5 px-4">
                        <div class="text-left mb-2">
                            <span class="text-secondary">{{ 'novocadastro2.descritivo' | translate }}</span>
                        </div>
                        <textarea class="input-material mb-0" id="description44"
                                  [(ngModel)]="modelResetStep.motive"
                                  style="width: 100%; resize: none"
                                  rows="7"
                                  maxlength="900" required></textarea>
                        <label class=" w-100 text-right" for="description44"
                        >
                            {{ 'novocadastro.maximo1' | translate }}
                            {{ handlerMaximumCharaters(modelResetStep.motive) }}
                            {{ 'novocadastro.maximo2' | translate }}</label>
                    </div>
                    <a href="javascript:void('');" (click)="actionResetStep()"
                       class="btn btn-block btn-primary mt-5">Enviar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Historic Audits -->
<div class="modal-custom modal fade" id="modalHistoryDetail" data-keyboard="false" data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="font-weight-bold text-primary">Histórico</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-custom table-striped" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>{{ 'history.date' | translate }}</th>
                                    <th>{{ 'history.responsible' | translate }}</th>
                                    <th>{{ 'history.reprobate' | translate }}</th>
                                    <th>{{ 'history.reason' | translate }}</th>
                                    <th>{{ 'history.realize' | translate }}</th>
                                </tr>
                                <tr *ngFor="let x of modelRequest.historyAudits">
                                    <td>{{ x.createdAt | date: 'dd/MM/yyyy' }}</td>
                                    <td>{{ x.auditBy?.name }}</td>
                                    <td>{{ x.stepFrom }} - {{ getNameStepByNumber(x.stepFrom) }}</td>
                                    <td>{{ x.motive }}</td>
                                    <td>{{ x.step + 1 }} - {{ getNameStepByNumber(x.step + 1) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-block d-xxl-flex">
    <div style="width: 100%;min-width: 60%" class="px-3">

        <div class="row mt-4 mb-3 px-3 px-md-0">
            <div class="col-12 col-md-2 px-0 px-xs-2 d-flex flex-nowrap">
                <a href="javascript:void('');"
                   (click)="step !== 1 ? handlerBack(step - 1) : router.navigate(['/my-list'])">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                </a>
                <a href="javascript: $('#modalBackStep').modal('show'); void('');" class="ml-4"
                   *ngIf="(modelRequest.step > 1 && (modelRequest.status !== 'approve' && (user.profile === 'product_commercial' || user.profile === 'administrator'))) && modelRequest.status !== 'archived'">
                    <svg-icon class="svg size-md" src="assets/svg/repair.svg"></svg-icon>
                </a>
                <a href="javascript: $('#modalHistoryDetail').modal('show'); void('');" class="ml-4"
                   *ngIf="modelRequest.historyAudits?.length > 0 && (modelRequest.status !== 'approve')">
                    <svg-icon class="svg size-md" src="assets/svg/files.svg"></svg-icon>
                </a>
            </div>
            <div class="col-7 col-lg-8 text-center">
                <span class="header-title" *ngIf="!isEdit">{{ 'novocadastro.title' | translate }}</span>
                <span class="header-title" *ngIf="isEdit">{{ 'novocadastro.titleDetail' | translate }}</span>
            </div>
            <div class="col-3 col-lg-2 px-0 px-xs-2">
                <span class="header-date">{{ 'novocadastro.criado' | translate }}
                    <strong *ngIf="!isEdit">{{ createaAt | date: 'dd/MM/yyyy' }}</strong>
                    <strong *ngIf="isEdit">{{ modelRequest.createdAt | date: 'dd/MM/yyyy' }}</strong></span>
            </div>
        </div>

        <div class="py-3 bg-beige rounded pb-5 px-1 px-xs-4 pt-4">
            <div class="nav_step shadow mx-auto mb-4"
                 [ngClass]="{'d-none': !(modelRequest.status == 'open'|| modelRequest.status == 'archived' || isNullOrUndefined(modelRequest.status))}">
                <ul class="nav d-flex justify-content-between" id="myTab" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step1-tab" data-toggle="tab" href="#step1" role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step-legend">Comercial</span>
                            <span class="step">1</span>
                            <div class="position_item" *ngIf="!stepOneDisabled">
                                <span class="position_item-stage">{{ 'minhalista.input8.title' | translate }}</span>
                                <div class="question"><span
                                        class="position_item-name">{{ 'novocadastro.etapa1.title' | translate }}</span>
                                </div>
                            </div>

                            <div class="position_item"
                                 *ngIf="stepOneDisabled && !this.isNullOrUndefined(handlerDateTitle(1))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}</span> <br>
                                    <div class="font-weight-600 text-truncate">{{ handlerNameTitle(1) }}</div>
                                    Comercial
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(1) }}</span>
                                    <!--<br>
                                    {{'novocadastro.etapa1.title' | translate}} -->
                                </span>
                            </div>
                        </a>
                    </li>

                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tab" data-toggle="tab" href="#step2" role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step-legend">
                                <img class="step-legend-brand"
                                     src="assets/images/country/{{modelRequest.area_400 === 'PMOINT' ? modelRequest.countryManufacturingPmoInt + '.svg' : 'brazil.svg'}}"
                                     alt=""/>
                                GQ DOC
                            </span>
                            <span class="step">2</span>
                            <div class="position_item text-right" *ngIf="!stepTwoDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Cadastro</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepTwoDisabled && !this.isNullOrUndefined(handlerDateTitle(5))">
                                <span class="position_item-name">
                                    <span class="font-weight-normal">{{ handlerDateTitle(6) | date: 'dd/MM/yyyy' }}</span> <br>
                                    <span class="font-weight-600 text-truncate">{{ handlerNameTitle(6) }}</span> <br>
                                    Cadastro
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(5) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(6) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>


            <div class="nav_step shadow mx-auto mb-5"
                 [ngClass]="{'d-none': !(!isNullOrUndefined(modelRequest.status) && (modelRequest.status !== 'open' && modelRequest.status !== 'archived'))}">
                <ul class="nav d-flex justify-content-between" id="myTabF" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step1-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 1, 'finish' : modelRequest.step >= 1}"
                           href="#step1" role="tab" aria-controls="step1" aria-selected="true">
                            <span class="step-legend">Comercial</span>
                            <span class="step">1</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    {{ 'novocadastro.etapa1.title' | translate }} <br>
                                    <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}
                                        <br>
                                        {{ handlerNameTitle(1) }}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(1) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(1) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tabF" data-toggle="tab"
                           [ngClass]="{'active' : modelRequest.step === 2, 'finish' : modelRequest.step >= 2}"
                           href="#step2" role="tab" aria-controls="step2" aria-selected="false">
                            <span class="step">2</span>
                            <div class="position_item">
                                <span class="position_item-stage">
                                    Cadastro <br>
                                    <span class="d-block font-weight-600">
                                        {{ handlerDateTitle(6) | date: 'dd/MM/yyyy' }}
                                        <br>
                                        {{ handlerNameTitle(6) }}
                                    </span>
                                </span>
                            </div>
                            <div class="finish_position_item">
                                <span class="position_item-stage">
                                    {{ handlerDateTitle(6) | date: 'dd/MM/yyyy' }}
                                    <span class="d-block font-weight-600"> {{ handlerNameTitle(6) }}</span>
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="tab-content" id="myTabContent">

            <!--request-one-->
            <div class="tab-pane fade show active" id="step1" role="tabpanel" aria-labelledby="step1-tab">
                <div class="mx-auto p-4 tab-pane-content large">
                    <div class="row mt-5">

                        <div class="col-12 col-md-6 pr-md-5">


                            <div class="input-material">
                                <input class="form-control" id="recordHolder2" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.AcronymCode" [disabled]="stepOneDisabled"/>
                                <label for="recordHolder2"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{ 'numeroMaterialParaTerceiro' | translate }}
                                    *</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <select id="center" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.center" [disabled]="stepOneDisabled">
                                    <option disabled selected [ngValue]="null">{{ 'general.selecione' | translate }}
                                    </option>
                                    <option *ngFor="let c of listCenter" [ngValue]="c.name">{{ c.name }}</option>
                                </select>
                                <label for="center"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'centro' | translate}}
                                    *</label>
                                <div></div>
                            </div>

                            <div class="input-material question" data-toggle="tooltip"
                                 data-placement="top"
                                 title="{{'informarmesmaunidadedecodigosap'|translate}}">
                                <select id="unity" class="form-control" name="unity"
                                        [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepOneDisabled">
                                    <option disabled selected
                                            [ngValue]="null">{{'general.selecione' | translate}}</option>
                                    <option *ngFor="let c of listMeasure" [ngValue]="c.name">{{c.description}}</option>
                                </select>
                                <label for="unity" [ngClass]="{'disabled' : stepOneDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('popovers.step2.pop3.title')"
                                       [attr.data-content]="getTranslateInstantKey('popovers.step2.pop3.text')">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="w-100">
                                <div class="text-left mb-2">
                                    <p class="font-weight-bold"><span
                                            class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span></p>
                                    <p>{{'step2.texto1' | translate}}</p>
                                </div>
                                <input class="form-control" id="descriptionIptStep3" [(ngModel)]="modelRequest.description"
                                       [readOnly]="false" [disabled]="stepOneDisabled" type="text" maxlength="33" required/>

                                <div class="w-100 d-block mb-4 mt-4"
                                     *ngIf="!stepOneDisabled">
                                    <a href="javascript:$('#modalDescriptionForm').modal('show'); void('');"
                                       class="btn btn-primary btn-block">{{'novocadastro.etapa2.input8' | translate}}</a>
                                </div>
                            </div>

                            <div class="input-material mt-4">
                                <input class="form-control" id="partnerName"  type="text" required
                                       [(ngModel)]="modelRequest.partnerName" [disabled]="stepOneDisabled"/>
                                <label for="partnerName"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'nomeTerceiro' | translate }}</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="netWeight" type="text" required
                                       [(ngModel)]="modelRequest.netWeight" [disabled]="stepOneDisabled"/>
                                <label for="netWeight"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'pesoLiquido' | translate }}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('pesoLiquido')"
                                       [attr.data-content]="'Unidade de venda'">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="grossWeight" type="text" required
                                       [(ngModel)]="modelRequest.grossWeight" [disabled]="stepOneDisabled"/>
                                <label for="grossWeight"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{'pesoBruto' | translate }}</label>
                                <div>
                                    <a href="javascript:void('')" class="question_beside d-block"
                                       data-toggle="popover"
                                       [attr.title]="getTranslateInstantKey('pesoBruto')"
                                       [attr.data-content]="'Caixa fechada / múltiplo'">
                                        <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 col-md-6 pl-md-5">

                            <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-20">
                                <div class="input-material w-100">
                                    <input class="form-control" id="ncm" maxlength="200" type="text" required
                                           [(ngModel)]="modelRequest.ncm" [disabled]="stepOneDisabled"/>
                                    <label for="ncm"
                                           [ngClass]="{'disabled' : stepOneDisabled}">{{ 'ncm' | translate }}
                                    </label>
                                    <div></div>
                                </div>
                                <div class="input-material w-100">
                                    <input class="form-control" id="expirationDate" maxlength="4" type="number" inputmode="numeric"
                                           [(ngModel)]="modelRequest.expirationDate" [disabled]="stepOneDisabled"  required />
                                    <label for="expirationDate"
                                           [ngClass]="{'disabled' : stepTwoDisabled}">{{'prazoValidadeMeses' | translate}}
                                        *</label>
                                    <div></div>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="euroAnalysis" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.euroAnalysis" [disabled]="stepOneDisabled"/>
                                <label for="euroAnalysis"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{ 'analisadoEurofarma' | translate }}
                                </label>
                                <div></div>
                            </div>


                            <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-20">
                                <div class="input-material w-100">
                                    <input class="form-control" id="productStatus"  type="text"
                                           [(ngModel)]="modelRequest.productStatus" [disabled]="stepOneDisabled" required />
                                    <label for="productStatus"
                                           [ngClass]="{'disabled' : stepOneDisabled}">{{ 'statusProduto' | translate }}
                                        *</label>
                                    <div>
                                        <a href="javascript:void('')" class="question_beside d-block"
                                           data-toggle="popover"
                                           [attr.title]="getTranslateInstantKey('statusProduto')"
                                           [attr.data-content]="'Preencher somente para o terceiro MSD'">
                                            <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple1" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple1" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple1"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    1</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple2" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple2" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple2"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    2</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple3" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple3" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple3"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    3</label>
                                <div></div>
                            </div>

                            <div class="input-material">
                                <input class="form-control" id="concentrationPrinciple4" maxlength="70" type="text"
                                       required
                                       [(ngModel)]="modelRequest.concentrationPrinciple4" [disabled]="stepTwoDisabled"/>
                                <label for="concentrationPrinciple4"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{ 'concentracaoPrincipioAtivo' | translate }}
                                    4</label>
                                <div></div>
                            </div>

                            <div class="input-material w-100">
                                <input class="form-control" id="pharmForm" type="text" required
                                       [(ngModel)]="modelRequest.pharmForm" [disabled]="stepOneDisabled" />
                                <label for="pharmForm"
                                       [ngClass]="{'disabled' : stepOneDisabled}">{{ 'formaFarmaceutica' | translate }}
                                </label>
                                <div></div>
                            </div>

                            <div class="input-material w-100">
                                <input class="form-control" id="storageCondition" maxlength="200" type="text"
                                       [(ngModel)]="modelRequest.storageCondition" [disabled]="stepTwoDisabled" required />
                                <label for="storageCondition"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'condicaoArmazenagem' | translate}}
                                    *</label>
                                <div></div>
                            </div>

                        </div>


                        <div class="col-12 mt-3" *ngIf="(stepOneDisabled && modelRequest.area_400 !== 'PMOINT') || (!stepOneDisabled && country === 'colombia')">

                            <div class="bg-beige rounded p-3">
                                <h6 class="text-primary">{{'general.labelCountry3' | translate}}</h6>
                                <p class="mb-2"
                                   *ngFor="let x of modelRequestResult.countryNotifications">{{getCountryNameByKey(x)}}</p>
                            </div>
                        </div>
                        <div class="col-12 mt-3" *ngIf="!stepOneDisabled && modelRequest.area_400 !== 'PMOINT'">
                            <div class="w-100">
                                <div class="bg-beige rounded p-3">
                                    <ng-container *ngIf="country == 'uruguai'">
                                        <h6 class="text-primary">{{'general.labelCountry1' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="uruguai"
                                                           (click)="handlerSelectedCountry('uruguai')"
                                                           [checked]="handlerCheckCountry('uruguai')"
                                                           [disabled]="handlerDisabledCountry('uruguai')">
                                                    <label class="custom-control-label"
                                                           for="uruguai">{{'country.uruguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="bolivia"
                                                           (click)="handlerSelectedCountry('bolivia')"
                                                           [checked]="handlerCheckCountry('bolivia')"
                                                           [disabled]="handlerDisabledCountry('bolivia')">
                                                    <label class="custom-control-label"
                                                           for="bolivia">{{'country.bolivia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="paraguai"
                                                           (click)="handlerSelectedCountry('paraguai')"
                                                           [checked]="handlerCheckCountry('paraguai')"
                                                           [disabled]="handlerDisabledCountry('paraguai')">
                                                    <label class="custom-control-label"
                                                           for="paraguai">{{'country.paraguai' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="handlerCheckCountryVisible()">
                                        <h6 class="text-primary">{{'general.labelCountry1' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="argentina1"
                                                           (click)="handlerSelectedCountry('argentina')"
                                                           [checked]="handlerCheckCountry('argentina')"
                                                           [disabled]="handlerDisabledCountry('argentina')">
                                                    <label class="custom-control-label"
                                                           for="argentina1">{{'country.argentina' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="bolivia1"
                                                           (click)="handlerSelectedCountry('bolivia')"
                                                           [checked]="handlerCheckCountry('bolivia')"
                                                           [disabled]="handlerDisabledCountry('bolivia')">
                                                    <label class="custom-control-label"
                                                           for="bolivia1">{{'country.bolivia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="brazil1"
                                                           (click)="handlerSelectedCountry('brazil')"
                                                           [checked]="handlerCheckCountry('brazil')"
                                                           [disabled]="handlerDisabledCountry('brazil')">
                                                    <label class="custom-control-label"
                                                           for="brazil1">{{'country.brazil' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="chile1"
                                                           (click)="handlerSelectedCountry('chile')"
                                                           [checked]="handlerCheckCountry('chile')"
                                                           [disabled]="handlerDisabledCountry('chile')">
                                                    <label class="custom-control-label"
                                                           for="chile1">{{'country.chile' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="colombia1"
                                                           (click)="handlerSelectedCountry('colombia')"
                                                           [checked]="handlerCheckCountry('colombia')"
                                                           [disabled]="handlerDisabledCountry('colombia')">
                                                    <label class="custom-control-label"
                                                           for="colombia1">{{'country.colombia' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="equador1"
                                                           (click)="handlerSelectedCountry('equador')"
                                                           [checked]="handlerCheckCountry('equador')"
                                                           [disabled]="handlerDisabledCountry('equador')">
                                                    <label class="custom-control-label"
                                                           for="equador1">{{'country.equador' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="mexico1"
                                                           (click)="handlerSelectedCountry('mexico')"
                                                           [checked]="handlerCheckCountry('mexico')"
                                                           [disabled]="handlerDisabledCountry('mexico')">
                                                    <label class="custom-control-label"
                                                           for="mexico1">{{'country.mexico' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="peru1"
                                                           (click)="handlerSelectedCountry('peru')"
                                                           [checked]="handlerCheckCountry('peru')"
                                                           [disabled]="handlerDisabledCountry('peru')">
                                                    <label class="custom-control-label"
                                                           for="peru1">{{'country.peru' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="paraguai1"
                                                           (click)="handlerSelectedCountry('paraguai')"
                                                           [checked]="handlerCheckCountry('paraguai')"
                                                           [disabled]="handlerDisabledCountry('paraguai')">
                                                    <label class="custom-control-label"
                                                           for="paraguai1">{{'country.paraguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="uruguai1"
                                                           (click)="handlerSelectedCountry('uruguai')"
                                                           [checked]="handlerCheckCountry('uruguai')"
                                                           [disabled]="handlerDisabledCountry('uruguai')">
                                                    <label class="custom-control-label"
                                                           for="uruguai1">{{'country.uruguai' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="venezuela"
                                                           (click)="handlerSelectedCountry('venezuela')"
                                                           [checked]="handlerCheckCountry('venezuela')"
                                                           [disabled]="handlerDisabledCountry('venezuela')">
                                                    <label class="custom-control-label"
                                                           for="venezuela">{{'country.venezuela' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="mocambique"
                                                           (click)="handlerSelectedCountry('mocambique')"
                                                           [checked]="handlerCheckCountry('mocambique')"
                                                           [disabled]="handlerDisabledCountry('mocambique')">
                                                    <label class="custom-control-label"
                                                           for="mocambique">{{'country.mocambique' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="angola"
                                                           (click)="handlerSelectedCountry('angola')"
                                                           [checked]="handlerCheckCountry('angola')"
                                                           [disabled]="handlerDisabledCountry('angola')">
                                                    <label class="custom-control-label"
                                                           for="angola">{{'country.angola' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="handlerCheckCountryVisible('ceam')">
                                        <h6 class="text-primary">{{'general.labelCountry2' | translate}}</h6>
                                        <div class="bg-beige py-3 rounded row mx-0 align-items-center mb-3">
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="belize2"
                                                           (click)="handlerSelectedCountry('belize', 'ceam')"
                                                           [checked]="handlerCheckCountry('belize', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('belize', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="belize2">{{'country.belize' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="costa-rica2"
                                                           (click)="handlerSelectedCountry('costa-rica', 'ceam')"
                                                           [checked]="handlerCheckCountry('costa-rica', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('costa-rica', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="costa-rica2">{{'country.costa-rica' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="guatemala2"
                                                           (click)="handlerSelectedCountry('guatemala', 'ceam')"
                                                           [checked]="handlerCheckCountry('guatemala', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('guatemala', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="guatemala2">{{'country.guatemala' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="honduras2"
                                                           (click)="handlerSelectedCountry('honduras', 'ceam')"
                                                           [checked]="handlerCheckCountry('honduras', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('honduras', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="honduras2">{{'country.honduras' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="nicaragua2"
                                                           (click)="handlerSelectedCountry('nicaragua', 'ceam')"
                                                           [checked]="handlerCheckCountry('nicaragua', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('nicaragua', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="nicaragua2">{{'country.nicaragua' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="panama2"
                                                           (click)="handlerSelectedCountry('panama', 'ceam')"
                                                           [checked]="handlerCheckCountry('panama', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('panama', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="panama2">{{'country.panama' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="republica-dominicana2"
                                                           (click)="handlerSelectedCountry('republica-dominicana', 'ceam')"
                                                           [checked]="handlerCheckCountry('republica-dominicana', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('republica-dominicana', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="republica-dominicana2">{{'country.republica-dominicana' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-4 py-1">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="el-salvador"
                                                           (click)="handlerSelectedCountry('el-salvador', 'ceam')"
                                                           [checked]="handlerCheckCountry('el-salvador', 'ceam')"
                                                           [disabled]="handlerDisabledCountry('el-salvador', 'ceam')">
                                                    <label class="custom-control-label"
                                                           for="el-salvador">{{'country.el-salvador' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div>
                                <a href="javascript:void('')" class="d-block question_beside" data-toggle="popover"
                                   [attr.data-content]="getTranslateInstantKey('wheninformingcountry')">
                                    <svg-icon src="assets/svg/questionmark.svg"></svg-icon>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <!--request-seven-->
            <div class="tab-pane fade" id="step2" role="tabpanel" aria-labelledby="step2-tab">
                <div class="row mt-4">

                    <div class="col-md-7">
                        <div class="px-3 py-4" style="max-height: 464px;overflow: auto;">
                            <div class="card bg-warning border-0 shadow-none">
                                <div class="row p-3">
                                    <strong class="col size-md">{{ 'novocadastro.etapa4.secao2.card1.criado' | translate }}</strong>
                                    <span class="col text-right size-md">{{ modelRequest.createdAt | date: 'dd/MM/yyyy' }}</span>
                                    <span class="col-12 size-xm pl-4">{{ modelRequest.createdBy?.name }}</span>
                                </div>
                            </div>

                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">{{ 'novocadastro.etapa1.title' | translate }}</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{ 'numeroMaterialParaTerceiro' | translate }}:
                                                <span class="text-primary">{{ modelRequest.AcronymCode }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'centro' | translate }}:
                                                <span class="text-primary">{{ modelRequest.center }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input3' | translate }}:
                                                <span class="text-primary">{{ modelRequest.unitMeasure }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input8' | translate }}:
                                                <span class="text-primary">{{ modelRequest.description }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'nomeTerceiro' | translate }}:
                                                <span class="text-primary">{{ modelRequest.partnerName }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'pesoLiquido' | translate }}:
                                                <span class="text-primary">{{ modelRequest.netWeight }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'pesoBruto' | translate }}:
                                                <span class="text-primary">{{ modelRequest.grossWeight }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'ncm' | translate }}:
                                                <span class="text-primary">{{ modelRequest.ncm }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'prazoValidadeMeses' | translate }}:
                                                <span class="text-primary">{{ modelRequest.expirationDate }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'analisadoEurofarma' | translate }}:
                                                <span class="text-primary">{{ modelRequest.euroAnalysis }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'statusProduto' | translate }}:
                                                <span class="text-primary">{{ modelRequest.productStatus }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }}:
                                                <span class="text-primary">{{ modelRequest.concentrationPrinciple1 }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'concentrationPrinciple2' | translate }}:
                                                <span class="text-primary">{{ modelRequest.concentrationPrinciple2 }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'concentrationPrinciple3' | translate }}:
                                                <span class="text-primary">{{ modelRequest.concentrationPrinciple3 }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'concentrationPrinciple4' | translate }}:
                                                <span class="text-primary">{{ modelRequest.concentrationPrinciple4 }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'formaFarmaceutica' | translate }}:
                                                <span class="text-primary">{{ modelRequest.pharmForm }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'condicaoArmazenagem' | translate }}:
                                                <span class="text-primary">{{ modelRequest.storageCondition }}</span>
                                            </span>

                                            <div class="col-12 mt-2">
                                                <span>{{ 'general.countryRequest' | translate }}:
                                                    <span class="text-primary"
                                                          *ngFor="let z of modelRequest.countryNotifications">{{ getCountryNameByKey(z) }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">{{ 'tributario' | translate }}</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{ 'classificacaoFiscal' | translate }}
                                                <span class="text-primary">{{ modelRequest.taxClassification }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'grupoCtgs' | translate }}
                                                <span class="text-primary">{{ modelRequest.groupCtgs }}</span>
                                            </span>
                                            <span class="col-12 mt-2">{{ 'grupoMaterial' | translate }}
                                                <span class="text-primary">{{ modelRequest.materialGroup1 }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>


                    <div class="col-md-5">
                        <div class="pl-5 my-4 pt-2 pr-5 border-left border-gray-text">
                            <div class="bg-success text-white rounded d-flex justify-content-center px-2 mb-2"
                                 *ngIf="modelRequest.status == 'approve'" style="width: fit-content">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                <span class="ml-1">{{ 'minhalista.concluido' | translate }}</span>
                            </div>

                            <ng-container *ngIf="modelRequest.status == 'disapprove'">
                                <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                                     style="width: fit-content">
                                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                                    <span class="ml-1">{{ 'minhalista.reprovado' | translate }}</span>
                                </div>
                                <div class="mt-3 mb-4">
                                    <p><strong>{{ 'general.step' | translate }}
                                        :</strong> {{ getNameStep(modelRequest.stepReprove) }}</p>
                                    <p><strong>{{ 'general.justify' | translate }}
                                        :</strong> {{ modelRequest.descriptionFinish }}</p>
                                </div>
                            </ng-container>

                            <div class="mb-4">
                                <a href="javascript:void('');" class="btn btn-block btn-primary" (click)="getExcel()">Exportar</a>
                            </div>

                            <ng-container *ngIf="modelRequest.status !== 'disapprove'">
                                <div class="input-material">
                                    <input class="form-control" id="SAP" type="text" maxlength="10"
                                           [disabled]="stepTwoDisabled"
                                           [(ngModel)]="modelRequest.codeSap"
                                           required/>
                                    <label for="SAP"
                                           [ngClass]="{'disabled': stepTwoDisabled}">{{ 'general.codeSAPCreate' | translate }}</label>
                                </div>
                                <!--                                <div class="input-material">-->
                                <!--                                    <input class="form-control" id="code400" type="text" maxlength="100"-->
                                <!--                                           [disabled]="stepFiveDisabled"-->
                                <!--                                           [(ngModel)]="modelRequest.code_400"-->
                                <!--                                           required/>-->
                                <!--                                    <label for="code400"-->
                                <!--                                           [ngClass]="{'disabled' : stepOneDisabled}">{{'general.codigo400' | translate}}*</label>-->
                                <!--                                </div>-->
                                <!--                                <div class="input-material">-->
                                <!--                                    <input class="form-control" id="motive400" type="text" maxlength="100"-->
                                <!--                                           [disabled]="stepFiveDisabled"-->
                                <!--                                           [(ngModel)]="modelRequest.motive_400"-->
                                <!--                                           required/>-->
                                <!--                                    <label for="motive400"-->
                                <!--                                           [ngClass]="{'disabled' : stepOneDisabled}">{{'general.motive400' | translate}}*</label>-->
                                <!--                                </div>-->
                            </ng-container>
                            <ng-container
                                    *ngIf="modelRequest.status == 'open' || isNullOrUndefined(modelRequest.status)">
                                <a href="javascript:void(0)" (click)="requestFinish('approve')"
                                   class="btn btn-block btn-warning mt-5"
                                   type="button">{{ 'novocadastro2.aprovar' | translate }}</a>
                                <a (click)="openModal()"
                                   class="btn btn-block btn-outline-primary mt-3"
                                   type="button">{{ 'novocadastro2.reprovar' | translate }}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <ng-container *ngIf="modelRequest.status == 'disapprove' && (step === modelRequest.step && step < 2)">
            <div class="d-flex flex-column align-items-center justify-content-center mb-4">
                <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                     style="width: fit-content">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                    <span class="ml-1">{{ 'minhalista.reprovado' | translate }}</span>
                </div>
                <div class="mt-3 mb-4">
                    <p><strong>{{ 'general.step' | translate }}
                        :</strong>{{ getNameStep(modelRequest.stepReprove) }}</p>
                    <p><strong>{{ 'general.justify' | translate }}
                        :</strong> {{ modelRequest.descriptionFinish }}</p>
                </div>
            </div>
        </ng-container>

        <div class="col-12 mb-4 text-right"
             *ngIf="step > 1 && step < 2 && stepTabSend && (modelRequest.status === 'open' || isNullOrUndefined(modelRequest.status))">
            <a (click)="openModal()"
               class="btn btn-outline-danger my-3"
               type="button">{{ 'novocadastro2.reprovar' | translate }}</a>
        </div>

        <div class="col-12 mx-auto mb-4" style="max-width: 400px"
             *ngIf="modelRequest.status !== 'disapprove' && modelRequest.status !== 'approve'">
            <a href="javascript:void(0)" (click)="actionSendTab()"
               *ngIf="step < 2 && (stepTabSend && (modelRequest.status === 'open' || isNullOrUndefined(modelRequest.status)))"
               class="btn btn-block btn-warning"
               type="button">{{ 'novocadastro.enviar' | translate }}</a>
            <a href="javascript:void(0)" (click)="actionNextTab()" *ngIf="stepTabNext"
               class="btn btn-block btn-warning"
               type="button">{{ 'novocadastro.next' | translate }}</a>
        </div>

    </div>

    <!--step == 4 || step == 5-->
    <div class="bg-beige px-3 py-4" *ngIf="false"
         style="width: 100%;min-width: 40%;overflow: auto;min-height: 709px;">
        <div class="card bg-warning border-0 shadow-none">
            <div class="row p-3">
                <strong class="col size-md">Criado por</strong>
                <span class="col text-right size-md">{{ modelRequest.createdAt | date: 'dd/MM/yyyy' }}</span>
                <span class="col-12 size-xm pl-4">{{ modelRequest.createdBy?.name }}</span>
            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">Novos Negócios</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <!--<span class="col-12 mt-2">{{'step4.sidecard.comercial-local.nome' | translate}}
                            <span class="text-primary">{{modelRequestResult.name}}</span>
                        </span>-->
                        <span class="col-12 mt-2">{{ 'general.codigomodelo' | translate }}
                            <span class="text-primary">{{ modelRequestResult.code }}</span>
                        </span>
                        <div class="col-12 mt-2">
                            <span> {{ 'general.countryRequest' | translate }}
                                <span class="text-primary d-inline-block mr-2"
                                      *ngFor="let x of modelRequestResult.countryNotifications">{{ getCountryNameByKey(x) }}</span>
                            </span>
                            <!--<img class="mr-2" width="30"
                                 src="assets/images/country/{{handlerFlagImage(modelRequestResult.country)}}" alt="country"/>
                            <span>{{'step4.sidecard.comercial-local.pais' | translate}}
                                <span class="text-primary">{{modelRequestResult.country}}</span>
                            </span>-->
                        </div>

                        <span class="col-12 mt-2">{{ 'centroProducao' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.centerModel }}</span>

                        <span class="col-12 mt-2">{{ 'canalDistribuicaoCodigoModelo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.channelModel }}</span>

                        <span class="col-12 mt-2">{{ 'motivoSolicitacao' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.reasonRequestInfo }}</span>

                        <span class="col-12 mt-2">{{ 'tipoMaterial' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.typeMaterial }}</span>

                        <span class="col-12 mt-2">{{ 'centro' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.center }}</span>

                        <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input2' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.channelDistribution }}</span>

                        <span class="col-12 mt-2">{{ 'descritivo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.description }}</span>

                        <span class="col-12 mt-2">{{ 'novocadastro.etapa2.input3' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.unitMeasure }}</span>

                        <span class="col-12 mt-2">{{ 'novocadastro.etapa4.secao2.card3.detentor' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.recordHolder }}</span>

                        <span class="col-12 mt-2">{{ 'expedidoPor' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.dispatchedBy }}</span>

                        <span class="col-12 mt-2">{{ 'fabricante' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.manufacturer }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 1</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple1 }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 2</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple2 }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 3</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple3 }}</span>

                        <span class="col-12 mt-2">{{ 'concentracaoPrincipioAtivo' | translate }} 4</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.concentrationPrinciple4 }}</span>

                    </div>
                </div>

            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">AR Brasil</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.motivo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.motive }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.canal' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.channelDistribution }}</span>
                        <span class="col-12 mt-2">{{ 'novocadastro2.descritivo' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.description }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.unidade' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{ modelRequestResult.unitMeasure }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.detentor' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.recordHolder }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.expedido' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.dispatchedBy }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.registro' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.ministryRecord }}</span>
                        <span class="col-12 mt-2">{{ 'step4.sidecard.Arlocal.validade' | translate }}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{ modelRequestResult.recordValidity | date: 'dd/MM/yyyy' }}</span>
                        <!--<span class="col-12 mt-2">{{'step4.sidecard.Arlocal.fabricante' | translate}}</span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.manufacturer}}</span>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Modal modalRegisterJustify -->
<div class="modal-custom modal fade" id="modalRegisterJustify" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRegisterJustifyLabel"
     aria-hidden="true"

>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{ 'general.justifyArea' | translate }}
                    </div>

                    <div class="bg-beige rounded pt-3 pb-1 px-4">
                        <div class="input-material mb-1">
                            <select class="form-control" [(ngModel)]="modelRequest.stepReprove">
                                <option [ngValue]="undefined">Selecione</option>
                                <ng-container *ngFor="let x of [1,2,3,4,5,6];">
                                    <option *ngIf="x < modelRequest.step +1" [ngValue]="x">{{ x }}
                                        - {{ x === 1 ? (getNameStepByNumber(x, this.getTranslateInstantKey('novocadastro.etapa1.title'))) : getNameStepByNumber(x) }}
                                    </option>
                                </ng-container>
                            </select>
                            <label class="mb-0 mr-3">Etapa</label>
                        </div>
                    </div>
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{ 'general.justifyReprove' | translate }}
                    </div>
                    <div class="py-3 bg-beige rounded pb-5 px-4">
                        <div class="text-left mb-2">
                            <span class="text-secondary">{{ 'novocadastro2.descritivo' | translate }}</span>
                        </div>
                        <textarea class="input-material mb-0" id="description3"
                                  style="width: 100%; resize: none"
                                  rows="7" [(ngModel)]="modelRequest.descriptionFinish"
                                  maxlength="900" required></textarea>
                        <label class=" w-100 text-right" for="description3"
                        >
                            {{ 'novocadastro.maximo1' | translate }}
                            {{ handlerMaximumCharaters(this.modelRequest.descriptionFinish) }}
                            {{ 'novocadastro.maximo2' | translate }}</label>
                    </div>
                    <a href="javascript:void('');" class="btn btn-block btn-primary mt-5"
                       (click)="requestFinish('disapprove')">Enviar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Description Form-->
<div class="modal-custom modal fade" id="modalDescriptionForm" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalDescriptionFormLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="subTitle text-gray-text">{{ 'modal.title' | translate }}</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small p-0">
                <div class="row mx-0 flex-column">
                    <div class="py-3 rounded pb-5 px-0 px-xs-4">

                        <div class="nav_step-modal shadow mx-auto mb-4 position-relative">
                            <div class="text-right m-auto description">
                                <div class="text-center mx-auto font-weight-bold text-primary primary"
                                     style="height: 48px;">
                                    {{ getDescriptionString() }}
                                </div>
                                <hr class="m-0">
                                <small class="text-right"
                                       *ngIf="((getDescriptionString().split('').length - 3) - 33) * (-1) >= 0">Restam {{ ((getDescriptionString().split('').length - 3) - 33) * (-1) }}
                                    caracteres.</small>
                                <small class="text-right"
                                       *ngIf="((getDescriptionString().split('').length - 3) - 33) * (-1) < 0">{{ 'modal.alert.quartenary' | translate }}</small>
                            </div>

                            <div class="position-relative" style="top: 10px;">
                                <ul id="mytabF-modal" role="tablist" class="nav d-flex justify-content-between">
                                    <li class="text-center">
                                        <a id="step1-tabF-modal" data-toggle="tab" href="#step1-modal" role="tab"
                                           aria-controls="step1-modal"
                                           aria-selected="true" class="nav-link-modal nav_step-secondary-item active">
                                            <span class="step">1</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step1.label' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step2-tabF-modal" data-toggle="tab" href="#step2-modal" role="tab"
                                           aria-controls="step2-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">2</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step2.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step3-tabF-modal" data-toggle="tab" href="#step3-modal" role="tab"
                                           aria-controls="step3-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">3</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step3.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step4-tabF-modal" data-toggle="tab" href="#step4-modal" role="tab"
                                           aria-controls="step4-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">4</span>
                                            <div class="position_item">
                                                <span class="position_item-stage">{{ 'modal.step4.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step5-tabF-modal" data-toggle="tab" href="#step5-modal" role="tab"
                                           aria-controls="step5-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">5</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step5.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step6-tabF-modal" data-toggle="tab" href="#step6-modal" role="tab"
                                           aria-controls="step6-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">6</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step6.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step7-tabF-modal" data-toggle="tab" href="#step7-modal" role="tab"
                                           aria-controls="step7-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">7</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step7.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="text-center">
                                        <a id="step8-tabF-modal" data-toggle="tab" href="#step8-modal" role="tab"
                                           aria-controls="step8-modal"
                                           aria-selected="false" class="nav-link-modal nav_step-secondary-item">
                                            <span class="step">8</span>
                                            <div class="position_item position_item_right">
                                                <span class="position_item-stage">{{ 'modal.step8.title' | translate }}</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr class="bg-primary w-90 mx-auto">

                        <div class="col-12 col-xl-10 mx-auto mt-4">
                            <div class="tab-content" id="myTabContent-modal">
                                <!--request-one-->
                                <div class="tab-pane fade show active" id="step1-modal" role="tabpanel"
                                     aria-labelledby="step1-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step1.label' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6">
                                            <p><strong> {{ 'modal.step1.title1' | translate }}</strong></p>
                                            <p>{{ 'modal.step1.text1' | translate }}</p>
                                            <p><strong> {{ 'modal.step1.title2' | translate }}</strong></p>
                                            <p> {{ 'modal.step1.text2' | translate }}</p>
                                            <p><strong> {{ 'modal.step1.exemple' | translate }}</strong></p>
                                            <p><b class="yellow_back">Alendronato</b> de sódio = <b class="yellow_back">ALENDRONATO</b>
                                                SOD</p>
                                            <p>Cloridrato de <b class="yellow_back">amitriptilina</b> = <b
                                                    class="yellow_back">AMITRIPTLINA</b> CLOR</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control text-uppercase" maxlength="200"
                                                           type="text"
                                                           [(ngModel)]="modelDescription.step0" required/>
                                                    <label>{{ 'modal.step1.label' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-two-->
                                <div class="tab-pane fade" id="step2-modal" role="tabpanel"
                                     aria-labelledby="step2-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step2.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{ 'modal.step2.text' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-7">
                                            <div class="bg-beige p-2 px-1 px-xs-3 rounded">
                                                <div class="input-material mt-4">
                                                    <input class="form-control" maxlength="200" type="text"
                                                           [ngModel]="modelDescription.step1"
                                                           (ngModelChange)="handlerBlockLetters($event,'step1')"
                                                           appOnlyLetters
                                                           required/>
                                                    <label>{{ 'modal.step2.label2' | translate }}</label>
                                                </div>
                                                <div class="input-material align-items-center d-flex"
                                                     *ngIf="!isNullOrUndefined(modelDescription.step1)">
                                                    <div class="w-100">
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'%'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">%</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MG'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MG</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MCG'"
                                                                   [ngModel]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MCG</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'G'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">G</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'ML'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">ML</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'L'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">L</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'UI'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">UI</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                            <input type="radio" class="custom-control-input"
                                                                   name="stepDescriptionConcentration2Radio"
                                                                   (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1lbl')"
                                                                   [value]="'MG/ML'"
                                                                   [(ngModel)]="modelDescription.step1lbl"
                                                            />
                                                            <label class="custom-control-label">MG/ML</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr/>

                                                <div class="custom-control custom-switch custom-switch-single">
                                                    <input type="checkbox" id="step1ConcAct2"
                                                           class="custom-control-input"
                                                           (ngModelChange)="actionisChangeDescriptionConcentration()"
                                                           [value]="false"
                                                           [(ngModel)]="modelDescription.step1isB"
                                                    />
                                                    <label for="step1ConcAct2"
                                                           class="custom-control-label">{{ 'modal.step2.label3' | translate }}</label>
                                                </div>
                                                <ng-container *ngIf="modelDescription.step1isB">
                                                    <div class="input-material mt-4">
                                                        <input class="form-control" maxlength="200" type="text"
                                                               [(ngModel)]="modelDescription.step1B"
                                                               (ngModelChange)="handlerBlockLetters($event,'step1B')"
                                                               appOnlyLetters
                                                               required/>
                                                        <label>{{ 'modal.step2.label3' | translate }}</label>
                                                    </div>
                                                    <div class="input-material align-items-center d-flex"
                                                         *ngIf="!isNullOrUndefined(modelDescription.step1B)">
                                                        <div class="w-100">
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'%'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">%</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MG'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MG</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MCG'"
                                                                       [ngModel]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MCG</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'G'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">G</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'ML'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">ML</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'L'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">L</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'UI'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">UI</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox custom-control-inline w-15">
                                                                <input type="radio" class="custom-control-input"
                                                                       name="stepDescriptionConcentration2BRadio"
                                                                       (ngModelChange)="actionChangeDescriptionConcentration($event, 'step1Blbl')"
                                                                       [value]="'MG/ML'"
                                                                       [(ngModel)]="modelDescription.step1Blbl"
                                                                />
                                                                <label class="custom-control-label">MG/ML</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!--request-three-->
                                <div class="tab-pane fade" id="step3-modal" role="tabpanel"
                                     aria-labelledby="step3-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step3.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6 offset-lg-3">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="register__checkbox">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="terms2" class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step2"/>
                                                        <label for="terms2"
                                                               class="custom-control-label">{{ 'modal.step3.text' | translate }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-four-->
                                <div class="tab-pane fade" id="step4-modal" role="tabpanel"
                                     aria-labelledby="step4-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step4.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6">
                                            <p>{{ 'modal.step4.text' | translate }}</p>
                                            <p><b>{{ 'modal.step4.exemplo' | translate }}</b></p>
                                            <p>{{ 'modal.step4.text2' | translate }}</p>
                                            <p>{{ 'modal.step4.text3' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control" maxlength="200" type="number"
                                                           [(ngModel)]="modelDescription.step3"
                                                           required/>
                                                    <label>{{ 'modal.step4.label' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-five-->
                                <div class="tab-pane fade" id="step5-modal" role="tabpanel"
                                     aria-labelledby="step5-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step5.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{ 'modal.step5.text' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <select class="form-control" (change)="handlerListIsBulk()"
                                                            [(ngModel)]="modelDescription.step4">
                                                        <option value="">Selecione</option>
                                                        <option *ngFor="let p of listPresentation"
                                                                [ngValue]="p.name">{{ p.description }}
                                                        </option>
                                                    </select>
                                                    <label>{{ 'modal.step5.title' | translate }}</label>
                                                </div>
                                                <div class="register__checkbox"
                                                     *ngIf="modelDescription.step4 === 'CAP' || modelDescription.step4 === 'CPR'">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="step4isBulk"
                                                               class="custom-control-input"
                                                               (change)="bulkStateToogle()"
                                                               [checked]="modelDescription.step4isBulk"
                                                               [(ngModel)]="modelDescription.step4isBulk"
                                                        />
                                                        <label for="step4isBulk"
                                                               class="custom-control-label">{{ 'general.bulk' | translate }}</label>
                                                    </div>
                                                </div>
                                                <!--<div class="register__checkbox mt-5">
                                                    <div class="custom-control custom-switch custom-switch-single">
                                                        <input type="checkbox" id="step5isSpace"
                                                               class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step5isSpace"
                                                        />
                                                        <label for="step5isSpace"
                                                               class="custom-control-label">{{'general.spacing' | translate}}</label>
                                                    </div>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-sixe-->
                                <div class="tab-pane fade" id="step6-modal" role="tabpanel"
                                     aria-labelledby="step6-tab-modal">
                                    <div class="row justify-content-center">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step6.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-4 d-flex align-items-center">
                                            <p>{{ 'modal.step6.text' | translate }}</p>
                                        </div>
                                        <div class="col-md-12 col-lg-6">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="input-material my-5">
                                                    <input class="form-control" maxlength="200" type="text"
                                                           [(ngModel)]="modelDescription.step5"
                                                           required/>
                                                    <label>{{ 'modal.step6.title' | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-seven-->
                                <div class="tab-pane fade" id="step7-modal" role="tabpanel"
                                     aria-labelledby="step7-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step7.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-md-12 col-lg-6 offset-lg-3">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="register__checkbox">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" id="muestra" class="custom-control-input"
                                                               [(ngModel)]="modelDescription.step6"
                                                        />
                                                        <label for="muestra"
                                                               class="custom-control-label">{{ 'modal.step7.muestra' | translate }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--request-eight-->
                                <div class="tab-pane fade" id="step8-modal" role="tabpanel"
                                     aria-labelledby="step8-tab-modal">
                                    <div class="row">
                                        <!--<div class="col-12 mt-5 mb-4">
                                            <h3 class="subTitle">{{'modal.step8.title' | translate}}</h3>
                                        </div>-->
                                        <div class="col-12">
                                            <p>{{ 'modal.step8.text' | translate }}</p>
                                        </div>
                                        <div class="col-12">
                                            <div class="bg-beige p-2 px-1 px-xs-5 rounded">
                                                <div class="assign-team my-4">
                                                    <div>
                                                        <a class="d-inline-flex align-items-center justify-content-center flex-column m-3"
                                                           *ngFor="let n of modelDescriptionCountry"
                                                           href="javascript:void('');"
                                                           (click)="handlerSelectedCountryDescription(n.name, n.description)"
                                                           [ngClass]="{'active' : n.name === modelDescription.step7 || (n.name === 'CHI' && modelDescription.step7 === 'CH')}">
                                                            <!--<svg-icon src="assets/images/country/{{n.thumb}}"></svg-icon>-->
                                                            <img src="assets/images/country/{{n.thumb}}" alt="" />
                                                            <span>{{ n.name === 'CHI' ? 'CH' : n.name }}</span>
                                                        </a>
                                                        <!--<a href="javascript:void('');" (click)="modelDescription.step7 = 'ARG'">
                                                            <img class="rounded-circle avatar-sm" alt="64x64"
                                                                src="assets/images/">
                                                        </a>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mx-auto mt-1 mt-xs-5 d-flex justify-content-around w-75">
                        <!--<a href="javascript:$('#modalDescriptionForm').modal('hide')"
                           class="btn btn-warning mx-2"
                           type="button">Fechar</a>-->

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep > 0"
                           (click)="prevStepModalDescription()"
                           class="btn btn-outline-primary px-1 px-xs-5"
                           type="button">{{ 'back' | translate }}</a>

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep < 7"
                           (click)="nextStepModalDescription()"
                           class="btn btn-warning px-1 px-xs-5"
                           type="button">Próximo</a>

                        <a href="javascript:void('');" *ngIf="modalDescriptionStep === 7"
                           (click)="finishStepModalDescription()"
                           class="btn btn-warning px-1 px-xs-5"
                           type="button">Finalizar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal BACK STEP -->
<div class="modal-custom modal fade" id="modalBackStep" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRegisterJustifyLabel"
     aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto font-weight-bold text-primary mt-2 mb-5 size-lg">
                        {{ 'general.resetRequestText' | translate }}
                    </div>
                    <div class="bg-beige rounded pt-3 pb-1 px-4">
                        <div class="input-material mb-1">
                            <select class="form-control" [(ngModel)]="modelResetStep.step">
                                <option [ngValue]="undefined">Selecione</option>
                                <ng-container *ngFor="let x of [1,2];">
                                    <option *ngIf="x < modelRequest.step" [ngValue]="x - 1">{{ x }}
                                        - {{ getNameStepByNumber(x) }}
                                    </option>
                                </ng-container>
                            </select>
                            <label class="mb-0 mr-3">Etapa</label>
                        </div>
                    </div>
                    <div class="py-3 bg-beige rounded pb-5 px-4">
                        <div class="text-left mb-2">
                            <span class="text-secondary">{{ 'novocadastro2.descritivo' | translate }}</span>
                        </div>
                        <textarea class="input-material mb-0" id="description44"
                                  [(ngModel)]="modelResetStep.motive"
                                  style="width: 100%; resize: none"
                                  rows="7"
                                  maxlength="900" required></textarea>
                        <label class=" w-100 text-right" for="description44"
                        >
                            {{ 'novocadastro.maximo1' | translate }}
                            {{ handlerMaximumCharaters(modelResetStep.motive) }}
                            {{ 'novocadastro.maximo2' | translate }}</label>
                    </div>
                    <a href="javascript:void('');" (click)="actionResetStep()"
                       class="btn btn-block btn-primary mt-5">Enviar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Historic Audits -->
<div class="modal-custom modal fade" id="modalHistoryDetail" data-keyboard="false" data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="font-weight-bold text-primary">Histórico</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-custom table-striped" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>{{ 'history.date' | translate }}</th>
                                    <th>{{ 'history.responsible' | translate }}</th>
                                    <th>{{ 'history.reprobate' | translate }}</th>
                                    <th>{{ 'history.reason' | translate }}</th>
                                    <th>{{ 'history.realize' | translate }}</th>
                                </tr>
                                <tr *ngFor="let x of modelRequest.historyAudits">
                                    <td>{{ x.createdAt | date: 'dd/MM/yyyy' }}</td>
                                    <td>{{ x.auditBy?.name }}</td>
                                    <td>{{ x.stepFrom }} - {{ getNameStepByNumber(x.stepFrom) }}</td>
                                    <td>{{ x.motive }}</td>
                                    <td>{{ x.step + 1 }} - {{ getNameStepByNumber(x.step + 1) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
